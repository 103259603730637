import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AgencyClientService {
  constructor(private http: HttpClient) {
  }

  getProfile(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/agency-client/profile/${id}`);
  }

  addBudget(orders_budget: any) {
    return this.http.post<any>(`${environment.apiUrl}/client/add-budget`,orders_budget);
  }
}