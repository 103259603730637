export class WageModel {
  id = null;
  role_id = null;
  description = null;
  amount = null;
  max_amount = null;
  currency = null;
  frequency = null;
  criteria_id = null;
  is_suplement = null;
  percentage = null;
  is_surcharge = null;
  highlight = false;
  current_template = false;
  isNotFilled = false;
  max_amount_visible = false;
  field_translations = null;
  salary_class_id = null;
  is_fixed_surcharge = false;

  constructor(id, role_id, description, amount, max_amount, currency, frequency, criteria_id, is_suplement, percentage, is_surcharge, field_translations, is_fixed_surcharge, salary_class_id = null) {
    this.id = id;
    this.role_id = role_id;
    this.description = description;
    this.amount = amount;
    this.max_amount = max_amount;
    this.currency = currency;
    this.frequency = frequency;
    this.criteria_id = criteria_id;
    this.is_suplement = is_suplement;
    this.percentage = percentage;
    this.is_surcharge = is_surcharge;
    this.field_translations = field_translations;
    this.is_fixed_surcharge = is_fixed_surcharge;
    this.salary_class_id = salary_class_id;

    if (max_amount > 0) {
      this.max_amount_visible = true;
    }
  }
}
