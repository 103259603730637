import { ErrorInterceptor } from './../_helpers/error.interceptor';
import { JwtInterceptor } from './../_helpers/jwt.interceptor';
import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './controllers/login.component';
import {RegisterComponent} from './controllers/register.component';
import {AdsComponent} from './controllers/ads.component';
import {LogoutComponent} from './controllers/logout.component';
import {AuthRoutingModule} from './auth-routing.routing';
import {HttpHelper} from '../helpers/common/http-helper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {StaffRegisterComponent} from './controllers/staff-register.component';
import {SharedModule} from '../helpers/shared.module';
import {NgPipesModule} from 'ngx-pipes';
import {PasswordResetComponent} from './controllers/password-reset.component';
import {OrderValidatedComponent} from './controllers/order-validated.component'
import {ToastModule} from 'primeng/toast';
import {RouterModule} from '@angular/router';
import {TranslateSharedModule} from '../helpers/translateSharedModule';
import {LanguageSwitcherModule} from '../helpers/controllers/language-switcher/language-switcher.module';
import {ProjectService, OrderService} from '../_services';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { CodeInputModule } from 'angular-code-input';
import {RegistrationGuard} from '../_guards/registration.guard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    SharedModule,
    NgPipesModule,
    TooltipModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    TranslateSharedModule,
    LanguageSwitcherModule,
    InputMaskModule,
    CodeInputModule
  ],
  declarations: [LoginComponent, RegisterComponent, StaffRegisterComponent, LogoutComponent, AdsComponent, PasswordResetComponent, OrderValidatedComponent],
  exports: [RouterModule],
  providers: [
    HttpHelper,
    ProjectService,
    OrderService,
    {provide: LOCALE_ID, useValue: 'en'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    RegistrationGuard
  ],
})
export class AuthModule {
}
