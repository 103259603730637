import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageSwitcherComponent} from './language-switcher.component';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateSharedModule} from '../../../helpers/translateSharedModule';


@NgModule({
    imports: [
        CommonModule,
        TooltipModule,
        TranslateSharedModule
    ], declarations: [
        LanguageSwitcherComponent
    ],
    exports: [
        LanguageSwitcherComponent
    ]
})
export class LanguageSwitcherModule {
}
