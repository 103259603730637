import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {PermissionService} from '../_services';

@Injectable()
export class EmployeeProfileGuard implements CanActivate {

  constructor(private router: Router, private permissionService: PermissionService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return !(!currentUser || (currentUser && currentUser.role !== 'employee'));
    // return this.permissionService.canAccessRoute('employee/profile');
  }
}
