import {Component, EventEmitter, Output} from '@angular/core';
import {CriteriaModel} from 'app/_models/criteria.model';
import {ProjectPlanningService} from 'app/_services';
import {MessageService, ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import { CriteriaTranslateModel } from './models/criteria-translate.model';

@Component({
  moduleId: module.id,
  selector: 'app-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria-style.scss']
})
export class CriteriaComponent {
  public currentLanguage = localStorage.getItem('Language');
  public showModal = false;
  criteria = new CriteriaModel();
  roleIndex = null;
  public fieldTranslations = new CriteriaTranslateModel();
  public canEdit = true;
  public updatingCriteria = false;
  public criteriaNameModal = false;

  public languages = [ 
    { value: 'en', label: 'English' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' }
  ];

  @Output() saved = new EventEmitter<any>();

  constructor(private projectPlanningService: ProjectPlanningService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService) {}

  ngOnInit() {
    // if (!this.criteria.from_time) {
    //   this.criteria.from_time = "00:00";
    // } 

    // if (!this.criteria.to_time) {
    //   this.criteria.to_time = "00:00"
    // }
    if(this.criteria.id) {
      console.log(this.criteria);
    }

    if (!this.criteria.after_hours) {
      this.criteria.after_hours = "00:00"
    }
  }

  show(type, name, index) {
    this.criteria.type = type;
    this.criteria.name = name;
    this.fieldTranslations = new CriteriaTranslateModel(); 
    this.fieldTranslations.name[this.currentLanguage] = name;
    this.roleIndex = index;
    this.canEdit = true;
    this.showModal = true;
  }

  editCriteria(criteriaId, canEdit = true) {
    this.showModal = true;
    this.canEdit = canEdit;
    this.projectPlanningService.getCriteria(criteriaId).subscribe( criteria => {
      this.criteria = criteria;
      this.fieldTranslations = JSON.parse(criteria.field_translations);
      this.criteria.from_time =new Date("01-01-2021 " + criteria.from_time + ":00");
      this.criteria.to_time =new Date("01-01-2021 " + criteria.to_time + ":00");
    })
  }

  close() {
    this.criteria = new CriteriaModel();
    this.roleIndex = null;
    this.showModal = false;
  }

  criteriaTypeChanged(type) {
    if (this.criteria[type]) {
      this.criteria.time = false;
      this.criteria.hour = false;
      this.criteria.no_criteria = false;
      this.criteria[type] = true;
    } else {
      this.criteria.no_criteria = true;
    }

    if(type === 'time') {
      this.criteria.from_time = new Date();
      this.criteria.from_time.setHours(23);
      this.criteria.from_time.setMinutes(0);;
      this.criteria.to_time = new Date();
      this.criteria.to_time.setHours(6);
      this.criteria.to_time.setMinutes(0);
    } else {
      this.criteria.from_time = null;
      this.criteria.to_time = null;
    }
  }

  onSubmit() {
    this.criteria.field_translations = JSON.stringify(this.fieldTranslations);
    if(this.criteria.time && this.criteria.from_time && this.criteria.to_time ) {
      this.criteria.from_time = this.prettyDate2(this.criteria.from_time);
      this.criteria.to_time = this.prettyDate2(this.criteria.to_time);
    }
    if (this.isCriteriaValid()) {
      this.criteria.id ? this.updatingCriteria = true : this.storeCriteria();
    }
  }

  selectedTab() {
    const languageIndex = { en: 0, de: 1, fr: 2, it: 3}
    return languageIndex[this.currentLanguage];
  }

  storeCriteria() {
    this.translate.get('Before proceeding, please review the settings for this criteria. Once saved, it will be editable only within agency settings. Are you sure you want to proceed?').subscribe(res => {
      this.confirmationService.confirm({
        header: this.translate.instant("Confirmation"),
        message: res,
        accept: () => {
          this.criteria.field_translations = JSON.stringify(this.fieldTranslations);
          this.projectPlanningService.storeCriteria(this.criteria).subscribe(response => {
            this.saved.emit({ criteria_id: response.criteria_id, index: this.roleIndex});
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant('Criteria saved successfully!')});
            this.close();
          });
        }
      });
    });
  }

  prettyDate2(date){
    let localeSpecificTime = date.toLocaleTimeString('de');
    console.log(localeSpecificTime);
    return localeSpecificTime.replace(/:\d+ /, ' ');
  }


  updateCriteria() {
    this.criteria.field_translations = JSON.stringify(this.fieldTranslations);
    this.projectPlanningService.updateCriteria(this.criteria.id, this.criteria).subscribe( response => {
      this.saved.emit({ criteria_id: response.criteria_id, index: this.roleIndex});
      this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant('Criteria saved successfully!')});
      this.close();
    });
  }

  createNewCriteria(templateName) {
    this.criteria.id = null;
    this.criteria.name = templateName;
    this.criteria.field_translations = JSON.stringify(this.fieldTranslations);
    this.projectPlanningService.storeCriteria(this.criteria).subscribe(
      response => {
        if(response.message == "error") {
          this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: this.translate.instant('This criteria template name already exists, please change the template name!')});
          return;
        }
        this.saved.emit({ criteria_id: response.criteria_id, index: this.roleIndex});
        this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant('Criteria saved successfully!')});
        this.criteriaNameModal = false;
        this.close();
      },
      error => {
        this.messageService.add({severity: 'error', summary: this.translate.instant('Error!')});
      }
    );
  }
  
  isCriteriaValid() {

    if(!this.criteria.name) {
      this.criteria.name = this.fieldTranslations.name[this.currentLanguage]
    }
    if (!this.criteria.name || this.criteria.name.trim().length === 0) {
        this.translate.get('Name is required').subscribe(res => {
            this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
      return false;
    }
    if (this.criteria.hour) {
      if (!this.criteria.after_hours) {
          this.translate.get('After hours is required').subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
        return false;
      }
    }
    if (this.criteria.time) {
      if (!this.criteria.from_time || !this.criteria.to_time) {
          this.translate.get('Time range is required').subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
        return false;
      }
    }
    return true;
  }

  textTranslate(lang, type) {
    if (type == 'name') {
      return (lang == 'de' ? 'Name' : lang == 'fr' ? 'Nom' : lang == 'it' ? 'Name' : 'Name').toUpperCase();
    } 
    
    if (type == 'criteria') {
      return (lang == 'de' ? 'Kriterien' : lang == 'fr' ? 'Critères' : lang == 'it' ? 'Criteria' : 'Criteria').toUpperCase();
    }

    if (type == 'use time criteria') {
      return (lang == 'de' ? 'Zeitkriterien anwenden' : lang == 'fr' ? 'utiliser des critères de temps' : lang == 'it' ? 'use time criteria' : 'use time criteria').toUpperCase();
    }

    if (type == 'use hour criteria') {
      return (lang == 'de' ? 'Stundenkriterien anwenden' : lang == 'fr' ? 'utiliser des critères d\'heure' : lang == 'it' ? 'use hour criteria' : 'use hour criteria').toUpperCase();
    }

    if (type == 'use no criteria') {
      return (lang == 'de' ? 'keine Kriterien anwenden' : lang == 'fr' ? 'n\'utilisez aucun critère' : lang == 'it' ? 'use no criteria' : 'use no criteria').toUpperCase();
    }

    if (type == 'time between') {
      return (lang == 'de' ? 'zeitfenster' : lang == 'fr' ? 'Fenêtre de temps' : lang == 'it' ? 'time between' : 'time between').toUpperCase();
    }

    if (type == 'after') {
      return (lang == 'de' ? 'nach' : lang == 'fr' ? 'apres' : lang == 'it' ? 'dopo' : 'after').toUpperCase();
    }

    if (type == 'days') {
      return (lang == 'de' ? 'tage' : lang == 'fr' ? 'journées' : lang == 'it' ? 'days' : 'days').toUpperCase();
    }

  }
}
