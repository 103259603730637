import {NgModule} from '@angular/core';
import {NgPipesModule} from 'ngx-pipes';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {PercentageDirective} from './directives/percentage.directive';
import {WagesComponent} from './controllers/wages/wages.component';
import {CustomTableComponent} from './controllers/custom-table/custom-table.component';
import {CriteriaComponent} from './controllers/criteria/criteria.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ExpensesComponent} from './controllers/expenses/expenses.component';
import {ClothingRequirementsComponent} from './controllers/clothing-requirements/clothing-requirements.component';
import {DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule} from 'ngx-dropzone-wrapper';
import {FilterComponent} from './controllers/filter/filter.component';
import {TranslateSharedModule} from './translateSharedModule';
import {GooglePlaceModule} from 'ng2-google-place-autocomplete/src/app/ng2-google-place.module';
import {TooltipModule} from 'primeng/tooltip';
import {InputMaskModule} from 'primeng/inputmask';
import {RatingModule} from 'primeng/rating';
import {RouterModule} from '@angular/router';
import {LoadTemplateModalModule} from './controllers/load-template-modal/load-template-modal.module';
import {CardTestModule} from './controllers/card/card-test.module';
import {AgencyService, UserService} from '../_services';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';
import {SliderModule} from 'primeng/slider';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {ToolbarModule} from 'primeng/toolbar';
import { SalaryClassComponent } from './controllers/salary-class/salary-class.component';
import {InputSwitchModule} from 'primeng/inputswitch';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    maxFilesize: 50,
    addRemoveLinks: true,
    dictRemoveFile: 'Remove',
};

@NgModule({
    declarations: [
        ClickOutsideDirective,
        WagesComponent,
        ExpensesComponent,
        ClothingRequirementsComponent,
        CriteriaComponent,
        FilterComponent,
        CustomTableComponent,
        PercentageDirective,
        SalaryClassComponent
    ],
    imports: [
        RouterModule,
        NgPipesModule,
        FormsModule,
        CommonModule,
        NgSelectModule,
        DialogModule,
        ConfirmDialogModule,
        ToastModule,
        CalendarModule,
        DropzoneModule,
        AccordionModule,
        SliderModule,
        TranslateSharedModule,
        GooglePlaceModule,
        TooltipModule,
        InputMaskModule,
        RatingModule,
        LoadTemplateModalModule,
        CardTestModule,
        TabViewModule,
        TableModule,
        ToolbarModule,
        InputSwitchModule
    ],
    exports: [
        ClickOutsideDirective,
        WagesComponent,
        ExpensesComponent,
        ClothingRequirementsComponent,
        FilterComponent,
        CriteriaComponent,
        CustomTableComponent,
        SalaryClassComponent
    ],
    providers: [
        UserService,
        AgencyService,
        {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG}]
})

export class SharedModule {
}
