export class CriteriaModel {
  id = null;
  name = null;
  type = null;
  time = false;
  hour = false;
  no_criteria = true;
  from_time = null;
  to_time = null;
  after_hours = null;
  monday = true;
  tuesday = true;
  wednesday = true;
  thursday = true;
  friday = true;
  saturday = true;
  sunday = true;
  agency_id = null;
  field_translations = null;
}
