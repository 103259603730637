import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) {
  }

  login(params) {
    return this.http.post<any>(`${environment.apiUrl}/login`, params);
  }

  validateAndSendCode(params) {
    return this.http.post<any>(`${environment.apiUrl}/validate-and-send-code`, params);
  }

  resendCode(params) {
    return this.http.post<any>(`${environment.apiUrl}/resend-code`, params);
  }

  register(params) {
    return this.http.post<any>(`${environment.apiUrl}/register`, params);
  }

  checkIfUserExists(params) {
    return this.http.post<any>(`${environment.apiUrl}/register-existing`, params);
  }

  staffRegister(params) {
    return this.http.post<any>(`${environment.apiUrl}/staff-register`, params);
  }

  getEmailFromToken(token) {
    return this.http.get<any>(`${environment.apiUrl}/invited/email/` + token);
  }

  resetRequest(param) {
    return this.http.post<any>(`${environment.apiUrl}/forgot-password`, param);
  }

  resendPasswordResetCode(param) {
    return this.http.post<any>(`${environment.apiUrl}/resend-password-reset-code`, param);
  }

  isResetPasswordCodeValid(param) {
    return this.http.post<any>(`${environment.apiUrl}/is-reset-password-code-valid`, param);
  }

  updatePassword(param) {
    return this.http.post<any>(`${environment.apiUrl}/reset-password`, param);
  }

  resetPasswordRequest(param) {
    return this.http.post<any>(`${environment.apiUrl}/reset-password-request`, param);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  resendConfirmEmail(param) {
      return this.http.post<any>(`${environment.apiUrl}/resend-confirm-email`, param);
  }
}
