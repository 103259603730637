import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AgencyService {
  constructor(private http: HttpClient) {
  }

  show(id) {
    return this.http.get<any>(`${environment.apiUrl}/agency/getById/${id}`);
  }

  inviteMember(invitation) {
    return this.http.post<any>(`${environment.apiUrl}/agency/invite-member`, invitation);
  }

  inviteClientMembers(invitation) {
    return this.http.post<any>(`${environment.apiUrl}/agency/invite-client-members`, invitation);
  }

  resendInvitation(email) {
    return this.http.post<any>(`${environment.apiUrl}/agency/resend-invite`, email);
  }


  public handleError = (error: Response) => {

      // Do messaging and error handling here

      return throwError(error)
  }

  store(agency) {
    return this.http.post<any>(`${environment.apiUrl}/agency/store`, agency);
  }

  updatePermissions(permissions) {
    return this.http.post<any>(`${environment.apiUrl}/agency/update-permissions`, permissions);
  }

  deleteInvitation(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/delete-invitation`, param);
  }

  reinviteUser(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/re-invite-user`, param);
  }

  getAgencies() {
    return this.http.get<any>(`${environment.apiUrl}/agency/index`);
  }

  getAgencyName(id) {
    return this.http.get<any>(`${environment.apiUrl}/agency/name/${id}`);
  }

  getSuperadminAgencies() {
    return this.http.get<any>(`${environment.apiUrl}/superadmin/agency/get-agencies`);
  }

  getAgencyBranches() {
    return this.http.get<any>(`${environment.apiUrl}/agency/branches`);
  }

  exportXml() {
    return this.http.get<any>(`${environment.apiUrl}/exportXML`);
  }

  exportTxt() {
    return this.http.get<any>(`${environment.apiUrl}/export-txt`);
  }

  checkIfOpenPayroll() {
    return this.http.get<any>(`${environment.apiUrl}/check-if-open-payroll`);
  }

  exportXLSX() {
    return this.http.get<any>(`${environment.apiUrl}/export-xlsx`);
  }
  
  downloadXLSX(param) {
    return this.http.post<any>(`${environment.apiUrl}/download-xlsx`, param);
  }

  deleteXLSXFileFromServer(param) {
    return this.http.post<any>(`${environment.apiUrl}/delete-xlsx-from-server`, param);
  }

  deletePayroll(id) {
    return this.http.delete<any>(`${environment.apiUrl}/delete-payroll/${id}`);
  }

  payslipPdfForAllEmployeesInOneMonth(param) {
      return this.http.post<any>(`${environment.apiUrl}/all-employees-pdf`, param);
  }

  getXMLs() {
    return this.http.get<any>(`${environment.apiUrl}/get-xmls`);
  }

  updateTemplate(id, template) {
    return this.http.put<any>(`${environment.apiUrl}/agency/template/${id}`, template);
  }

  loadTemplates(type) {
    return this.http.get<any>(`${environment.apiUrl}/agency/template?type=${type}`);
  }

  getProjectTypes() {
    return this.http.get<any>(`${environment.apiUrl}/project/project-types`);
  }

  deleteTemplate(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/template/${id}`);
  }

  removeClothingTemplateImage(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/agency/clothing-template/${id}`, param);
  }

  deleteProjectType(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/project-type/${id}`);
  }

  editProjectType(id, projectType) {
    return this.http.put(`${environment.apiUrl}/project/project-type/${id}`, projectType);
  }

  getProfile(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/agency/profile/${id}`);
  }

  getProjectReport(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/project-report`, param);
  }

  getWords(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/get-words`, param);
  }

  updateTranslatation(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/updateTranslatation`, param);
  }

  saveTranslatation(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/saveTranslatation`, param);
  }

  getNotTranslated(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/getNotTranslated`, param);
  }

  getTranslated(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/getTranslated`, param);
  }

  addCredits(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/add-credits`, param);
  }

  getAgencyCredits(agencyId) {
      return this.http.get<any>(`${environment.apiUrl}/agency/get-credits/${agencyId}`);
  }

  getAgencyTotalCredits(agencyId) {
      return this.http.get<any>(`${environment.apiUrl}/agency/get-total-credits/${agencyId}`);
  }

  generateXLSX(param) {
      return this.http.post<any>(`${environment.apiUrl}/agency/generate-xlsx`, param);
  }

  updateTermsAndConditions(param) {
      return this.http.put(`${environment.apiUrl}/agency/update-tos`, param);
  }

  updateClockTolerance(param) {
    return this.http.put(`${environment.apiUrl}/agency/update-clock-tolerance`, param);
  }

  upladteEarlyClockTolerance(param) {
    return this.http.put(`${environment.apiUrl}/agency/update-early-clock-tolerance`, param);
  }
  
  updateWorkPermissionExpiryDelayTolerance(param) {
    return this.http.put(`${environment.apiUrl}/agency/update-work-permission-expiry-delay-tolerance`, param);
  }
  
  updateShiftDelayTimes(param) {
    return this.http.put(`${environment.apiUrl}/agency/update-shift-delay-times`, param);
  }

  updateEmployeeListNumbers(param) {
    return this.http.put(`${environment.apiUrl}/agency/update-employee-list-numbers`, param);
  }

  updateEmailInfo(id, param) {
      return this.http.put<any>(`${environment.apiUrl}/agency/update-email-info/${id}`, param);
  }

  removeTermsAndConditionsFile(id) {
      return this.http.delete(`${environment.apiUrl}/agency/tos-file/${id}`);
  }

  removeLogo(id) {
      return this.http.delete(`${environment.apiUrl}/agency/logo/${id}`);
  }

  searchEmployees(param) {
      return this.http.get<any>(`${environment.apiUrl}/employee/settings?search=${param}`)
  }

  selectedEmployee(id) {
      return this.http.get<any>(`${environment.apiUrl}/employee/settings/${id}`)
  }

  updateSelectedEmployee(param) {
      return this.http.put<any>(`${environment.apiUrl}/employee/settings`, param)
  }

  addPools(param) {
      return this.http.post<any>(`${environment.apiUrl}/employee/add-pools`, param);
  }

  removePools(param) {
      return this.http.post<any>(`${environment.apiUrl}/employee/remove-pools`, param);
  }

  storeBulkAPC(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/active-permanent-contract/store-contracts`, param);
  }

  updateNotifyEmail(param) {
      return this.http.put(`${environment.apiUrl}/agency/update-notify-email`, param);
  }

  updateTwilioSettings(param) {
      return this.http.put(`${environment.apiUrl}/agency/update-twilio-settings`, param);
  }

  getAgencyFields(agencyId) {
      return this.http.get<any>(`${environment.apiUrl}/agency/fields/${agencyId}`);
  }

  updateAgencyFields(agencyId, param) {
      return this.http.put<any>(`${environment.apiUrl}/agency/fields/${agencyId}`, param);
  }

  storeAgencyContact(param) {
      return this.http.post<any>(`${environment.apiUrl}/agency/contact`, param);
  }

  storeHrManagers(param) {
      return this.http.post<any>(`${environment.apiUrl}/agency/hr-managers`, param);
  }

  getAgencyContacts(agencyId) {
      return this.http.get<any>(`${environment.apiUrl}/agency/contacts/${agencyId}`);
  }

  deleteAgencyContact(id) {
      return this.http.delete<any>(`${environment.apiUrl}/agency/contact/${id}`);
  }

  updateUserRole(param) {
      return this.http.put<any>(`${environment.apiUrl}/user/role`, param);
  }

  storeBreak(param) {
      return this.http.post<any>(`${environment.apiUrl}/agency/break`, param);
  }

  getBreaks() {
      return this.http.get<any>(`${environment.apiUrl}/agency/breaks`);
  }

  removeBreak(id) {
      return this.http.delete<any>(`${environment.apiUrl}/agency/break/${id}`);
  }

  deleteEmployee(id) {
    return this.http.delete<any>(`${environment.apiUrl}/superadmin/employee/${id}`);
  }

  deleteRoleFile(id, body) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/template/${id}/role-file`, body);
  }

  getAgencyPermanentEmployeeGroups(agencyId) {
    return this.http.get<any>(`${environment.apiUrl}/agency/permanent-employee-group/${agencyId}`);
  }

  storePermanentEmployeeGroup(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/permanent-employee-group`, body);
  }

  updatePermanentEmployeeGroup(id, body) {
    return this.http.put<any>(`${environment.apiUrl}/agency/permanent-employee-group/${id}`, body);
  }

  deletePermanentEmployeeGroup(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/permanent-employee-group/${id}`);
  }

  getAgencyPermanentEmployees() {
    return this.http.get<any>(`${environment.apiUrl}/agency/permanent-employee-group/employees`);
  }

  addOrRemoveEmployeeFromGroup(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/permanent-employee-group/employees`, body);
  }

  getAbsentCategories(agencyId) {
    return this.http.get<any>(`${environment.apiUrl}/agency/absence-categories/${agencyId}`);
  }

  storeAbsentCategory(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/absence-categories`, body);
  }

  updateAbsentCategory(id, body) {
    return this.http.put<any>(`${environment.apiUrl}/agency/absence-categories/${id}`, body);
  }

  deleteAbsentCategory(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/absence-categories/${id}`);
  }

  getPublicHolidays() {
    return this.http.get<any>(`${environment.apiUrl}/agency/public-holiday`);
  }

  storePublicHolidays(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/public-holiday`, body);
  }

  importHolidaysFromXls(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/public-holiday/import`, body);
  }

  updatePublicHolidays(id, body) {
    return this.http.put<any>(`${environment.apiUrl}/agency/public-holiday/${id}`, body);
  }

  deletePublicHolidays(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/public-holiday/${id}`);
  }

  getMandatoryFields() {
    return this.http.get<any>(`${environment.apiUrl}/agency/mandatory-field`);
  }

  storeMandatoryField(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/mandatory-field`, body);
  }

  deleteMandatoryField(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/mandatory-field/${id}`);
  }

  getEditFields() {
    return this.http.get<any>(`${environment.apiUrl}/agency/edit-field`);
  }

  storeEditField(body) {
    return this.http.post<any>(`${environment.apiUrl}/agency/edit-field`, body);
  }

  deleteEditField(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/edit-field/${id}`);
  }

  getSedCards(){
    return this.http.get<any>(`${environment.apiUrl}/agency/sed-card-templates`);
  }

  postSedCards(model){
    return this.http.post<any>(`${environment.apiUrl}/agency/sed-card-templates`, model);
  }

  setLowCreditsEmail(data) {
    return this.http.put<any>(`${environment.apiUrl}/agency/low-credits-email`, data);
  }

  setAssignmentNotifications(data) {
    return this.http.put<any>(`${environment.apiUrl}/agency/assignment-notifications`, data);
  }

  //NOH
  nohWorkingHours(working_hours){
    return this.http.post<any>(`${environment.apiUrl}/agency/noh-working-hours`,{working_hours});
  }
  nohStepOne(project_type_id,agency_contact_id){
    return this.http.post<any>(`${environment.apiUrl}/agency/noh-step-one`,{project_type_id,agency_contact_id});
  }
  nohBuffer(buffer_time){
    return this.http.post<any>(`${environment.apiUrl}/agency/noh-buffer`,{buffer_time});
  }
  autoAssignmentAfterXMin(auto_assginment_after_x_mins){
    return this.http.post<any>(`${environment.apiUrl}/agency/auto-assginment-after-x-mins`,{auto_assginment_after_x_mins});
  }
  nohNotificationTemplate(email_template_id){
    return this.http.post<any>(`${environment.apiUrl}/agency/noh-notification-template`,{email_template_id});
  }
  getNohSwitch(){
    return this.http.get<any>(`${environment.apiUrl}/agency/noh-switch`);
  }

    //sed-card-templates
    getSedCardTemplates(){
      return this.http.get<any>(`${environment.apiUrl}/agency/sed-card-templates`)
    }
    getSedCardTemplateById(id){
      return this.http.get<any>(`${environment.apiUrl}/agency/sed-card-templates/${id}`)
    }
    postSedCardTemplates(name,body,agency_id){
      return this.http.post<any>(`${environment.apiUrl}/agency/sed-card-templates`,{name,body,agency_id})
    }
    editSedCardTemplates(id,name,body,agency_id){
      return this.http.put<any>(`${environment.apiUrl}/agency/sed-card-templates/${id}`,{name,body,agency_id})
    }
    deleteSedCardTemplates(id){
      return this.http.delete<any>(`${environment.apiUrl}/agency/sed-card-templates/${id}`)
    }
}
