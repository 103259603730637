import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SidebarComponent} from './template-components/sidebar.component';
import {TopbarComponent} from './template-components/topbar.component';
import {DefaultComponent} from '../pages/default/default.component';
import {LayoutComponent} from './layout/layout.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../helpers/shared.module';
import {NgPipesModule} from 'ngx-pipes';
import {ClickElsewhereDirective} from './template-components/click-elsewhere-directive';
import {TranslateSharedModule} from '../../helpers/translateSharedModule';
import {ProjectService} from '../../_services';
import {LanguageSwitcherModule} from '../../helpers/controllers/language-switcher/language-switcher.module';

@NgModule({
  declarations: [
    SidebarComponent,
    TopbarComponent,
    DefaultComponent,
    LayoutComponent,
    ClickElsewhereDirective
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    DefaultComponent,
    LayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    NgPipesModule,
    TranslateSharedModule,
    LanguageSwitcherModule
  ],
  providers: [
      ProjectService
  ]
})
export class LayoutModule {
}
