import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './controllers/login.component';
import { RegisterComponent } from './controllers/register.component';
import { StaffRegisterComponent } from './controllers/staff-register.component';
import { AdsComponent } from './controllers/ads.component';
import {PasswordResetComponent} from './controllers/password-reset.component';
import {OrderValidatedComponent} from './controllers/order-validated.component';
import {LoginGuard} from '../_guards';
import {RegistrationGuard} from '../_guards/registration.guard';

const routes: Routes = [
    { path: 'login', canActivate: [LoginGuard], component: LoginComponent },
    { path: 'register',canActivate: [RegistrationGuard], component: RegisterComponent},
    { path: 'ads', component: AdsComponent},
    { path: 'register/:id', component: RegisterComponent},
    { path: 'staff/register/:token', component: StaffRegisterComponent},
    { path: 'password-reset/:token', component: PasswordResetComponent},
    { path: 'orders/verify/:token', component: OrderValidatedComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [RegistrationGuard]
})
export class AuthRoutingModule {
}
