import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component'; // main content
import {AuthModule} from './auth/auth.module';
import {ErrorsModule} from './helpers/errors/errors.module';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {CmsRoutingModule} from './cms/cms-routing.module';
import {CmsComponent} from './cms/cms.component';
import {LayoutModule} from './cms/layout/layout.module';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';

// translations

import {TranslatePipe} from '@ngx-translate/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CommonModule} from '@angular/common';
import {PreviousRouteService} from './common/services/previous-route.service';
import {ErrorInterceptor, JwtInterceptor} from './_helpers';
import {AuthenticationService, PermissionService} from './_services';
import {EmployeeProfileGuard, LoginGuard, ProjectGuard, RedirectingGuard, WorkValidationGuard, ClientWorkValidationGuard} from './_guards';
import {EmployeeGuard} from './_guards';
import {AgencyGuard} from './_guards/';
import {ClientGuard} from './_guards';
import {AgencyClientGuard} from './_guards';
import {AuthGuard} from './auth/controllers/auth.guard';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { DashboardComponent } from './cms/pages/default/dashboard/dashboard.component';
// const authConfig = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(AppConfig.googleClientId)
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(AppConfig.facebookClientId)
//   }
// ]);

// export function provideConfig() {
//   return authConfig;
// }

registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    RouterModule,
    AuthModule,
    ErrorsModule,
    AppRoutingModule,
    CmsRoutingModule,
    LayoutModule,
    HttpClientModule,
    ToastModule,
    // AngularFireModule.initializeApp({
    //   apiKey: 'AIzaSyBM-5MzyZoh0hCJJtU0JrJCvcSbQR1f_44',
    //   authDomain: 'faststaff-1499240022388.firebaseapp.com',
    //   databaseURL: 'https://faststaff-1499240022388.firebaseio.com',
    //   projectId: 'faststaff-1499240022388',
    //   storageBucket: 'faststaff-1499240022388.appspot.com',
    //   messagingSenderId: '816694338201'
    // }),
    // AngularFirestoreModule,
    // AngularFireDatabaseModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
  ],
  providers: [
    MessageService,
    // {
    // provide: AuthServiceConfig,
    // useFactory: provideConfig
    // },
    PreviousRouteService,
    AuthenticationService,
    PermissionService,
    AuthGuard,
    ProjectGuard,
    EmployeeGuard,
    EmployeeProfileGuard,
    AgencyGuard,
    ClientGuard,
    AgencyClientGuard,
    RedirectingGuard,
    LoginGuard,
    WorkValidationGuard,
    ClientWorkValidationGuard,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  exports: [TranslateModule, TranslatePipe],
  declarations: [AppComponent, CmsComponent, DashboardComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
