import {Component, EventEmitter, Output} from '@angular/core';
import {SalaryClassModel} from 'app/_models/salary-class.model';
import {ProjectPlanningService} from 'app/_services';
import {MessageService, ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import { SalaryClassTranslateModel } from './models/salary-class-translate.model';


@Component({
  selector: 'app-salary-class',
  templateUrl: './salary-class.component.html',
  styleUrls: ['./salary-class.component.css']
})
export class SalaryClassComponent {
  public currentLanguage = localStorage.getItem('Language');
  public showModal = false;
  salaryClass = new SalaryClassModel();
  roleIndex = null;
  public fieldTranslations = new SalaryClassTranslateModel();
  public salaryClassKey = null;
  public canEdit = true;

  public languages = [ 
    { value: 'en', label: 'English' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' }
  ];

  @Output() saved = new EventEmitter<any>();

  constructor(private projectPlanningService: ProjectPlanningService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService) { }

    ngOnInit() {
      if(this.salaryClass.id) {
        console.log(this.salaryClass);
      }
    }
    
    show(name, key, index) {
      this.salaryClass.name = name;
      this.salaryClassKey = key;
      this.fieldTranslations = new SalaryClassTranslateModel(); 
      this.fieldTranslations.name[this.currentLanguage] = name;
      this.roleIndex = index;
      this.canEdit = true;
      this.showModal = true;
    }
  
    editSalaryClass(salaryClassId, canEdit = true) {
      this.showModal = true;
      this.canEdit = canEdit;
      this.projectPlanningService.getSalaryClass(salaryClassId).subscribe( sClass => {
        this.salaryClass = sClass;
        this.fieldTranslations = JSON.parse(sClass.field_translations);
        this.salaryClassKey = sClass.salary_class_key;
        console.log("sClass.salary_class_key: ", sClass.salary_class_key)
      })
    }
  
    close() {
      this.salaryClass = new SalaryClassModel();
      this.roleIndex = null;
      this.showModal = false;
    }
  
    onSubmit() {
      this.salaryClass.field_translations = JSON.stringify(this.fieldTranslations);
      this.salaryClass.salary_class_key = this.salaryClassKey;
      if (this.isSalaryClassValid()) {
        this.salaryClass.id ? this.updateSalaryClass() : this.storeSalaryClass();
      }
    }
  
    selectedTab() {
      const languageIndex = { en: 0, de: 1, fr: 2, it: 3}
      return languageIndex[this.currentLanguage];
    }
  
    storeSalaryClass() {
      this.translate.get('Before proceeding, please review the settings for this salary class. Once saved, it will be editable only within agency settings. Are you sure you want to proceed?').subscribe(res => {
        this.confirmationService.confirm({
          header: this.translate.instant("Confirmation"),
          message: res,
          accept: () => {
            this.salaryClass.field_translations = JSON.stringify(this.fieldTranslations);
            this.salaryClass.salary_class_key = this.salaryClassKey;
            this.projectPlanningService.storeSalaryClass(this.salaryClass).subscribe(response => {
              this.saved.emit({ salary_class_id: response.salary_class_id, index: this.roleIndex});
              this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant('Salary class saved successfully')});
              this.close();
            });
          }
        });
      });
      
    }
  
    updateSalaryClass() {
      this.translate.get('Before proceeding, are you sure you want to update this salary class? Please be aware that any changes made to its settings will be applied to all projects where this salary class is being used.').subscribe(res => {
        this.confirmationService.confirm({
          header: this.translate.instant("Confirmation"),
          message: res,
          accept: () => {
            this.salaryClass.field_translations = JSON.stringify(this.fieldTranslations);
            this.salaryClass.salary_class_key = this.salaryClassKey;
            this.projectPlanningService.updateSalaryClass(this.salaryClass.id, this.salaryClass).subscribe( response => {
              this.saved.emit({ salary_class_id: response.salary_class_id, index: this.roleIndex});
              this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant('Salary class saved successfully')});
              this.close();
            });
          }
        });
      });

      
    }
  
    isSalaryClassValid() {
  
      if(!this.salaryClass.name) {
        this.salaryClass.name = this.fieldTranslations.name[this.currentLanguage]
      }
      if (!this.salaryClass.name || this.salaryClass.name.trim().length === 0) {
          this.translate.get('Name is required').subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
        return false;
      }

      if (!this.salaryClass.salary_class_key) {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('Error!'),
          detail: this.translate.instant('Salary Class Key is required'),
        });
        return false;
      }

      return true;
    }
  
    textTranslate(lang, type) {
      if (type == 'name') {
        return (lang == 'de' ? 'Name' : lang == 'fr' ? 'Nom' : lang == 'it' ? 'Name' : 'Name').toUpperCase();
      }   
      if (type == 'key') {
        return (lang == 'de' ? 'Schlüssel' : lang == 'fr' ? 'clé' : lang == 'it' ? 'chiave' : 'key').toUpperCase();
      }   
    }

}			
