import {Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef} from '@angular/core';
import {AgencyService, EmployeeService, FilterService} from 'app/_services';
import {FilterModel, LanguageModel, AvailabilityModel} from 'app/_models/filter.model';
import {ProjectPlanningService, ProjectService} from 'app/_services';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppConfig} from '../../common/app.config';
import {Router} from '@angular/router';
import {DropdownOptions} from '../../../_models/dropdown-options';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-employee-filter',
  templateUrl: `./filter.component.html`,
  styleUrls: ['./filter-style.scss'],
  providers: [MessageService, FilterService, ProjectPlanningService, EmployeeService]
})
export class FilterComponent implements OnInit {

  public dropDownOptions = DropdownOptions;
  branches = [];
  bodyHeight = [];
  filter: FilterModel = new FilterModel();
  templates = [];
  template = null;
  getUserPictureThumbnailPath = AppConfig.getUserPictureThumbnailPath;

  role_id = null;
  showShiftAndDate = false;
  isSubmitted = false;
  contentEditable = [];
  isSubmittedAsTemplate = false;
  filteredEmployeesVb = false;
  saveAsTemplateInput = false;
  sendEmailModalVb = false;
  saveAsListInput = false;
  saveAsBlacklistInput = false;
  searchEmployeeByName = '';
  employeesFilteredAmount = 0;
  employeesFiltered = [];
  seenFilteredEmployees = [];
  countTotalEmployeesMatched = 0;
  employeeCount = null;
  includingPublicEmployeesCount = null;
  employeesDeselected = 0;
  initialGroups = [];
  groups = [];
  blacklistGroups = [];
  emailSubject = null;
  emailBody = null;
  loading = false;
  publicDiff = 0;
  filterFromEmployeeDashboard = false;
  showFilterAvailabilityChbx = false;
  unavailablePeriods = [];

  showModal = false;
  public showTemplateModal = false;
  public removeFBA = false;

  public currentLanguage = localStorage.getItem('Language');

  public agencyName = environment.agencyName;

  public loadingGroups = false;
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));

  public consultants = [];
  public is_easytemp_agency = false;


  @Output() saved = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();
  @Input() saveAsList = false;
  @Input() changeFilteringAddress = false;
  @Input() publicPool = false;
  @Input() isArchived = 0;

  public autocompleteOptions = {
    componentRestrictions: {country: 'CH'}
  };
  filterClone: FilterModel;


  constructor(private filterService: FilterService,
              private agencyService: AgencyService,
              private router: Router,
              private messageService: MessageService,
              private projectPlanningService: ProjectPlanningService,
              private employeeService: EmployeeService,
              public translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private confirmationService: ConfirmationService) {
    // this.clearFilter();
    for (let index = 100; index < 210; index++) {
      this.bodyHeight.push(index);
    }
  }

  ngOnInit() {
    this.projectPlanningService.loadTemplates('filter').subscribe(response => {
      this.templates = response.templates;
    });

    this.agencyService.getAgencyBranches().subscribe(response => {
      var branchesResp: any = [];
      for(var i=0; i < response.branches.length; i++){
        var branch: any = {
          name: response.branches[i].name,
          value: response.branches[i].id + ""
        }
        branchesResp.push(branch);
      }
      this.branches = branchesResp;
    });

    this.employeeService.getAgencyGroups({branches: this.filter.branch_types}).subscribe(response => {
      this.initialGroups = response.groups.map(gr => {
        return {name: gr.name, id: gr.id};
      });

      this.groups = response.groups.map(gr => {
        return {name: gr.name, id: gr.id};
      });
    });

    this.employeeService.getAgencyBlacklistGroups().subscribe(response =>{
      this.blacklistGroups = response['blacklist-groups'].map(group => {
        return {name: group.name, id: group.id}
      })
    });

    this.agencyService.getAgencyName(this.currentUser.agency_id).subscribe(r1 => {
      let currentAgName = r1[0];
      this.is_easytemp_agency = currentAgName.toLowerCase().includes('hotelis') || currentAgName.toLowerCase().includes('KSK') ? true : false;
      if (this.is_easytemp_agency) {
          this.employeeService.getConsultants({agency_name: currentAgName}).subscribe((res) => {
              this.consultants = res.consultants;
          });
      }
    });

  }

  getMultipleAgencyGroups() {
    // if(
    //   this.filter?.branch_types?.length > 1 || 
    //   (this.filter?.branch_types?.length == 1 && (this.filter?.branch_types[0] != this.currentUser.agency_id))){
    //   this.loadingGroups = true;

    //   this.employeeService.getMultipleAgencyGroups(this.filter?.branch_types).subscribe(response => {
    //     this.groups = response.groups.map(gr => {
    //       return {name: gr.name, id: gr.id};
    //     });

    //     this.loadingGroups = false;
    //   })
    // }
  }

  resetMultipleAgencyGroups() {
    if(!this.filter.other_branches) {
      this.groups = this.initialGroups.map(gr => {
        return {name: gr.name, id: gr.id};
      });
    }
  }

  show() {
    this.employeeCount = null;
    this.countTotalEmployeesMatched = null;

    this.filterClone = JSON.parse(JSON.stringify(this.filter));

    this.filterChanged(true);
    this.showModal = true;
    this.getEmployeeLists();
    this.getBlackLists();
  }

  getEmployeeLists() {
    // if(this.filter?.branch_types?.length > 0) {
      this.employeeService.getAgencyGroups({branches: this.filter.branch_types}).subscribe(response => {
  
          const groupsArray = Array.isArray(response?.groups) ? response.groups : [];
  
          this.groups = groupsArray.map(gr => {
              return {name: gr.name, id: gr.id};
          });
  
          const missingGroupIds = [];
          this.filter.employee_lists = this.filter.employee_lists.filter(id => {
              const found = this.groups.some(group => group.id === id);
              if (!found) {
                  missingGroupIds.push(id);
              }
              return found;
          });
  
          if(missingGroupIds.length > 0) {
              this.filterChanged();
          }
      });
    // } else {
    //   this.groups = [];
    //   this.filter.employee_lists = [];
    // }
  }

  getBlackLists() {
    this.employeeService.getAgencyBlacklistGroups().subscribe(response =>{
      this.blacklistGroups = response['blacklist-groups'].map(group => {
        return {name: group.name, id: group.id}
      })
    })
  }

  filterChanged(initialLoad = false) {
    this.loading = true;

    this.filterService.filteredEmployeesAmount(this.filter).subscribe(response => {
      this.publicDiff = response.public_diff;
      if (!Array.isArray(response.employees)) {
        response.employees = Object.keys(response.employees).map((key) => [Number(key), response.employees[key]]);
      }
    
      let selectedEmployeeCount = 0;
      this.employeesFiltered = response.employees.map(emp => {
        let isEmployeeSelected = false;

        if(this.filter.is_deselect_clicked){
          if(this.filter.preselected_employee_ids.includes(emp.id)) {
            isEmployeeSelected = true;
            selectedEmployeeCount++;
          } else {
            isEmployeeSelected = false;
          }
        } else if(!this.filter.is_deselect_clicked){
          if(!this.filter.deselected_employee_ids.includes(emp.id)) {
            isEmployeeSelected = true;
            selectedEmployeeCount++;
          } else {
            isEmployeeSelected = false;
          }
        }

        return {...emp, selected: isEmployeeSelected};
      });

      this.seenFilteredEmployees = [...this.employeesFiltered];
      this.countTotalEmployeesMatched = this.seenFilteredEmployees.length;
      this.employeeCount = selectedEmployeeCount;
      this.searchEmployeeByName = '';
      this.sortEmployees();
      this.loading = false;
    });
  }

  sortEmployees() {
    this.seenFilteredEmployees.sort((a, b) => {
      if (a.selected === b.selected) {
        return a.last_name.localeCompare(b.last_name);
      } else if (a.selected) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  otherBranchesChanged(){
    this.filter.other_branches = true;
  }

  respondSaved() {
    console.log(this.filter);
    this.filterService.filteredEmployeesAmount(this.filter).subscribe(response => {
      this.employeesFiltered = response.employees.map(emp => {
        return {...emp, selected: true};
      });
      this.seenFilteredEmployees = [...this.employeesFiltered];
      this.countTotalEmployeesMatched = this.seenFilteredEmployees.length;
      this.employeeCount = this.seenFilteredEmployees.length;
      this.saved.emit({filteredEmployees: this.employeesFiltered, filterAmount: this.employeesFiltered.length, filter: this.filter});
      this.isSubmitted = false;
      this.close();
    });
  }

  close() {
    this.clearFilter();
    this.isSubmitted = false;
    this.isSubmittedAsTemplate = false;
    this.showModal = false;
    this.searchEmployeeByName = '';
    this.employeesFiltered = [];
    this.seenFilteredEmployees = [];
    this.employeeCount = 0;
    this.filteredEmployeesVb = false;
    this.closed.emit(true);

  }

  loadFilter(template) {
    this.filter = JSON.parse(template.body);
    this.filter.deselected_employee_ids = [];
    this.getEmployeeLists();
    this.filterChanged();
  }

  deselectedEmployeesFill() {
    if (!this.filter.deselected_employee_ids) {
      this.filter.deselected_employee_ids = [];
    }
    for (let i = 0; i < this.employeesFiltered.length; i++) {
      if (!this.employeesFiltered[i].selected) {
        this.filter.deselected_employee_ids.push(this.employeesFiltered[i].id);
      }
    }
  }

  saveFilterAsTemplate() {
    if (!this.filter.name) {
        this.translate.get('To save a template filter, name is required!').subscribe(res => {
            this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
      return;
    }
    this.isSubmitted = true;
    this.deselectedEmployeesFill();
    const filter = this.filter;
    filter.deselected_employee_ids = [];
    this.projectPlanningService.saveTemplate({
      name: this.filter.name, type: 'filter',
      body: JSON.stringify(filter)
    }).subscribe(response => {
      if (response.status === 'error') {
          this.translate.get(response.message).subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
      } else if (response.status === 'success') {
        this.templates = response.templates;
        this.respondSaved();
      }
    }, err => {
      this.isSubmitted = false;
    });
    this.isSubmittedAsTemplate = true;
  }

  saveFilter() {
    // this.deselectedEmployeesFill();
    // this.saveEmployeeSelection();
    this.respondSaved();
  }

  saveEmployeeSelection() {
    console.log(this.seenFilteredEmployees);
    let preSelectedEmployeeIds = [];
    let deSelectedEmployeeIds = [];
    this.seenFilteredEmployees.forEach(emp => {
      if(emp.selected) {
        preSelectedEmployeeIds.push(emp.id);
      } else {
        deSelectedEmployeeIds.push(emp.id);
      }
    });

    this.filter.deselected_employee_ids = deSelectedEmployeeIds;
    this.filter.preselected_employee_ids = preSelectedEmployeeIds;
  }

  saveList() {
    if (this.filter.name && this.filter.name.length > 1) {
      this.isSubmitted = false;

      const filteredIds = this.employeesFiltered.reduce( (result, employee) => {
        if(this.filter.is_deselect_clicked){
          if (this.filter.preselected_employee_ids.includes(employee.id)) {
            return [...result, employee.id];
          }
        } else {
          if (!this.filter.deselected_employee_ids.includes(employee.id)) {
            return [...result, employee.id];
          }
        }
        return result;
      }, []);

      this.employeeService.addListToNewGroup({group: this.filter.name, employee_ids: filteredIds})
        .subscribe(response => {
          this.filter.name = '';
            this.translate.get('List saved!').subscribe(res => {
                this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
            });
        });
    } else {
        this.translate.get('Please provide a list name!').subscribe(res => {
            this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
    }
  }

  saveBlacklist() {
    if (this.filter.name && this.filter.name.length > 1) {
      this.isSubmitted = false;

      const filteredIds = this.employeesFiltered.reduce( (result, employee) => {
        if(this.filter.is_deselect_clicked){
          if (this.filter.preselected_employee_ids.includes(employee.id)) {
            return [...result, employee.id];
          }
        } else {
          if (!this.filter.deselected_employee_ids.includes(employee.id)) {
            return [...result, employee.id];
          }
        }
        return result;
      }, []);

      this.employeeService.addBlacklistToNewGroup({group: this.filter.name, employee_ids: filteredIds})
        .subscribe(response => {
          this.filter.name = '';
            this.translate.get('List saved!').subscribe(res => {
                this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
            });
        });
    } else {
        this.translate.get('Please provide a list name!').subscribe(res => {
            this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
    }
  }

  clearFilter() {
    this.filter = new FilterModel();
    var currentUserAgencyId = JSON.parse(localStorage.getItem('currentUser'))?.agency_id + "";
    this.filter.branch_types = [currentUserAgencyId];
    this.filterChanged();
  }

  getAddress($event, addressNr) {
    if (addressNr === 1) {
      this.filter.main_address = $event.formatted_address;
      this.filter.latitude = $event.geometry.location.lat();
      this.filter.longitude = $event.geometry.location.lng();
    } else {
      this.filter.second_address = $event.formatted_address;
      this.filter.latitude = $event.geometry.location.lat();
      this.filter.longitude = $event.geometry.location.lng();
    }
  }

  addLanguage() {
    if (!this.filter.languages || !this.filter.languages.length) {
      this.filter.languages = [];
    }
    this.filter.languages.push(new LanguageModel());
  }

  removeLanguage(i) {
    this.filter.languages.splice(i, 1);
    this.filterChanged();
  }

  addAvailability() {
    if (!this.filter.weekly_availabilities || !this.filter.weekly_availabilities.length) {
      this.filter.weekly_availabilities = [];
    }
    this.filter.weekly_availabilities.push(new AvailabilityModel());
  }

  removeAvailability(i) {
    this.filter.weekly_availabilities.splice(i, 1);
    this.filterChanged();
  }

  trackByFn(i: number) {
    return i;
  }

  showFilterTemplates() {
    this.showTemplateModal = true;
  }

  searchByName() {
    if (this.searchEmployeeByName.length === 0) {
      this.seenFilteredEmployees = [...this.employeesFiltered];
      return;
    }
    this.seenFilteredEmployees = this.employeesFiltered.filter( x => {
      const first_name = x.first_name.toLowerCase();
      const last_name = x.last_name.toLowerCase();
      return (first_name.indexOf(this.searchEmployeeByName.toLowerCase()) > -1)
        || (last_name.indexOf(this.searchEmployeeByName.toLowerCase()) > -1);
    });
  }

  selectAllEmployees() {
    this.filter.is_deselect_clicked = false;
    this.filter.deselected_employee_ids = [];
    this.filter.preselected_employee_ids = [];
    this.employeesDeselected = 0;
    this.seenFilteredEmployees.forEach(emp => {
      emp.selected = true;
    });

    this.employeeCount = this.seenFilteredEmployees.length;
  }

  deselectAllEmployees() {
    this.filter.is_deselect_clicked = true;
    this.filter.preselected_employee_ids = [];
    this.employeesFiltered = this.employeesFiltered.map(emp => {
      return {...emp, selected: false, showConflictModal: false};
    });
    this.seenFilteredEmployees = [...this.employeesFiltered];
    this.countTotalEmployeesMatched = this.seenFilteredEmployees.length;
    this.employeeCount = 0;
    this.searchEmployeeByName = '';
    this.employeesDeselected = this.employeesFiltered.length;
  }

  countDeselected() {
    this.employeesDeselected = 0;
    this.employeeCount = 0;
    const deselectedEmps = [];
  
    for (let i = 0; i < this.employeesFiltered.length; i++) {
      const employeeId = this.employeesFiltered[i].id;
  
      if (this.filter.is_deselect_clicked) {
        if (this.employeesFiltered[i].selected) {
          this.employeeCount++;
          if (!this.filter.preselected_employee_ids.includes(employeeId)) {
            this.filter.preselected_employee_ids.push(employeeId);
          }
        } else {
          this.employeesDeselected++;
          const index = this.filter.preselected_employee_ids.indexOf(employeeId);
          if (index !== -1) {
            this.filter.preselected_employee_ids.splice(index, 1);
          }
        }
      } else {
        if (!this.employeesFiltered[i].selected) {
          this.employeesDeselected++;
          deselectedEmps.push(employeeId);
        } else {
          this.employeeCount++;
          const sid = this.filter.deselected_employee_ids.indexOf(employeeId);
          if (sid >= 0) {
            this.filter.deselected_employee_ids.splice(sid, 1);
          }
        }
      }
    }
  
    if (!this.filter.is_deselect_clicked) {
      this.filter.deselected_employee_ids = deselectedEmps.concat(this.filter.deselected_employee_ids.filter(function (item) {
        return deselectedEmps.indexOf(item) < 0;
      }));
    }

    this.sortEmployees();
  }

  sendEmailToSelectedEmployees() {
    this.filterService.sendEmailToSelectedEmployees({
        employees: this.employeesFiltered,
        subject: this.emailSubject,
        body: this.emailBody
    }).subscribe();
  }

  over() {
    this.showShiftAndDate = true;
  }

  out() {
    this.showShiftAndDate = false;
  }

  addUnavailablePeriod() {
    this.filter.availablePeriods.push({from: null, to: null});
    this.filterChanged();
  }

  removeUnavailablePeriod(index) {
    this.filter.availablePeriods.splice(index, 1);
    this.filterChanged();
  }

  removeDeselection() {
    // this.filter.is_deselect_clicked = false;
  }

  changeIsDeselectClicked(event) {
    console.log(event.checked);
    if(event.checked) {
      this.filter.is_deselect_clicked = true;
    } else {
      this.filter.is_deselect_clicked = false;
    }
    this.cdr.detectChanges();
  }
}
