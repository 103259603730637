import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadTemplateModalComponent} from './load-template-modal.component';
import {DialogModule} from 'primeng/dialog';
import {TranslateSharedModule} from '../../translateSharedModule';
import {NgPipesModule} from 'ngx-pipes';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        TranslateSharedModule,
        NgPipesModule
    ], declarations: [
        LoadTemplateModalComponent
    ],
    exports: [
        LoadTemplateModalComponent
    ]
})
export class LoadTemplateModalModule {
}
