import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LogoutComponent} from './auth/controllers/logout.component';
import {RedirectingGuard} from './_guards';
import {LoginComponent} from './auth/controllers/login.component';
import {ToastModule} from 'primeng/toast';

const routes: Routes = [
    {path: 'login', loadChildren: './auth/auth.module#AuthModule'},
    {path: 'logout', component: LogoutComponent},
    {path: '', canActivate: [RedirectingGuard], component: LoginComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        ToastModule,
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
