import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../_services';

@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher-style.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LanguageSwitcherComponent implements OnInit {
    public currentLang: any = '';
    public mobileLangHolder = false;
    url: string;
    constructor(public router: Router,
                private translate: TranslateService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.currentLang = localStorage.getItem('Language');
        if (this.currentLang == null) {
            this.currentLang = 'de';
            localStorage.setItem('Language', 'de');

        }
        this.url = this.router.url
        this.translate.setDefaultLang(this.currentLang);
    }

    changeLang(lang) {
        this.currentLang = lang;
        localStorage.setItem('Language', lang);
        const url = window.location.href;
        const arr = url.split('/');
        const result = arr[0] + '//' + arr[2] + this.router.url;
        this.updateUserLanguage(lang, result);
    }

    updateUserLanguage(lang, result) {

        const user = JSON.parse(localStorage.getItem('currentUser'));
        if (user) {
            const userId = user.id;
            this.userService.updateUserLanguage({language: lang}, userId).subscribe(
                response  => {
                    window.location.href = result;
                });
        } else {
            window.location.href = result;
        }
    }

}
