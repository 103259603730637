import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AppConfig} from '../../../helpers/common/app.config';
import {TranslateService} from '@ngx-translate/core';
import {SidebarPermissionsModel} from '../../../_models/sidebar-permissions.model';
import {PermissionService, ProjectService} from '../../../_services';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./template-components-style.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TopbarComponent implements OnInit {

    public user: any;
    // public currentLang: any = '';
    public hidden = false;
    profilePicturePath = '';
    public isDropdownVisible = false;
    public permissions = new SidebarPermissionsModel();
    public employee;
    public logoPath = null;
    public agencyName = null;

    public favIconUrl = environment.favIconUrl;

    constructor(private router: Router,
                private translate: TranslateService,
                private permissionService: PermissionService,
                private projectService: ProjectService,
    ) {
    }

    ngOnInit() {

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        if (!this.user.first_name) { this.user.first_name = ''; }
        if (!this.user.last_name) { this.user.last_name = ''; }
        // this.currentLang = localStorage.getItem('Language');

        if (this.user.agency_id !== 0) {
            this.agencyNameAndLog();
        }

        if (this.user.profile_picture) {
          this.profilePicturePath = AppConfig.getUserPicturePath(this.user.id)
            + '/thumbnails/web/user-profile-avatar/' + this.user.profile_picture;
        }
        // if (this.currentLang == null) {
        //     this.currentLang = 'de';
        //     localStorage.setItem('Language', 'de');
        // }
        this.hidden = localStorage.getItem('sidebar') !== null;
        this.showHideSidebar();
        // this.translate.setDefaultLang(this.currentLang);
        this.permissionService.sidebarPermissions().subscribe(response => {
            this.permissions = response;

            this.employee = false;
            if (!this.permissions.agency_profile
                || !this.permissions.clients || !this.permissions.employees
                || !this.permissions.locations || !this.permissions.new_project
                || !this.permissions.projects) this.employee = true;
        });


    }

  // changeLang(lang) {
  //   this.currentLang = lang;
  //   localStorage.setItem('Language', lang);
  //   const url = window.location.href;
  //   const arr = url.split('/');
  //   const result = arr[0] + '//' + arr[2] + this.router.url;
  //   window.location.href = result;
  //   // this.router.navigate([this.router.url]);
  // }

  showHideSidebar() {
        if (this.hidden) {
            document.body.className = 'aside-open';
            this.hidden = false;
            localStorage.setItem('sidebar', 'open');
        } else {
            document.body.className = 'aside-hidden';
            this.hidden = true;
            localStorage.removeItem('sidebar');
        }
    }


    hoverOnSidebar() {
        document.body.className = 'aside-open';
        this.hidden = false;
        localStorage.setItem('sidebar', 'open');
    }

    agencyNameAndLog() {
        if (!this.user.agency_id) return;

        if(this.user.role === 'employee') {
            let aId = 1;
            let aName = 'job.rocks';
            if(environment.agencyName === 'Hotelis') {
                aId = 28;
                aName = 'Hotelis';
            }
            
            this.projectService.getAgencyLogoPath(aId).subscribe( response => {
                if (response.logo_path) this.logoPath = AppConfig.getAgencyFileParh(aId) + response.logo_path;
                this.agencyName = aName;
            });
        } else {
            this.projectService.getAgencyLogoPath(this.user.agency_id).subscribe( response => {
                if (response.logo_path) this.logoPath = AppConfig.getAgencyFileParh(this.user.agency_id) + response.logo_path;
                this.agencyName = response.agency_name;
            })
        }

    }
}
