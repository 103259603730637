export class ClothingTranslateModel  {
    subject = {
        en: '',
        de: '',
        fr: '',
        it: '',
    };
    description = {
        en: '',
        de: '',
        fr: '',
        it: '',
    };
}
