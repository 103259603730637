import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class WorkValidationService {
  constructor(private http: HttpClient) {
  }

  createTimestamps(projectType) {
    return this.http.post<any>(`${environment.apiUrl}/hire/timestamp`, projectType);
  }

  addTimestamp(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/add-timestamp`, params);
  }

  unvalidateShifts(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/unvalidate`, params);
  }

  deleteExpense(param) {
    return this.http.delete<any>(`${environment.apiUrl}/hire/delete-expense/`, param);
  }

  storeShiftExpenses(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/store-expenses`, params);
  }

  validateTimestamp(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/validate-timestamp`, params);
  }

  getTimestampsHistory(allocationId) {
    return this.http.get<any>(`${environment.apiUrl}/hire/timestamps-history/${allocationId}`);
  }

  storeExpense(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/store-expense`, params);
  }

  storeSalary(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/store-salary`, params);
  }

  storeRenumeration(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/store-renumeration`, params);
  }

  addShiftSalary(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/add-shift-salary`, params);
  }

  addShiftExpense(params) {
    return this.http.post<any>(`${environment.apiUrl}/hire/add-shift-expense`, params);
  }

  addComment(id, params) {
    return this.http.put<any>(`${environment.apiUrl}/hire/allocation/add-comment/${id}`, params);
  }
}
