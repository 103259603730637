import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class GeneralService {
    constructor(private http: HttpClient) {
    }

    generateXL(param) {
        return this.http.post<any>(`${environment.apiUrl}/generate-excel-file`, param);
    }

    planningExport(param) {
        return this.http.post<any>(`${environment.apiUrl}/planning-export`, param);
    }

    ordersplanningExport(param) {
        return this.http.post<any>(`${environment.apiUrl}/orders-planning-export`, param);
    }

}
