import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserIdentity} from '../../helpers/common/user.identity';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _router: Router, private http: HttpClient) {

  }

  private getLang() {
    let language = localStorage.getItem('Language');

    if (language == null) {
      language = 'en';
    }

    return language;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (UserIdentity.isAuthenticated() === false) {

      this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }


    // const user = UserIdentity.getUser();
    // const translateService = new TranslateService(this.http);
    // const param = {module: state.url, language: this.getLang(), agency_id: user.agency_id};
    // const isAuth = new Observable<boolean>(observer => {
    // translateService.loadTranslatationModule(param).subscribe(res => {
    //   if (UserIdentity.isAuthenticated()) {
    //     if (UserIdentity.getUser().role === 'employee') {
    //       const hasAccess = this.urlAccess(user.id).filter(a => a.role === 'employee' && a.url === state.url).length > 0;
    //       if (hasAccess === false) {
    //         this._router.navigate(['/employee/profile']);
    //         observer.next(false);
    //         observer.complete()
    //       } else {
    //         observer.next(true);
    //         observer.complete()
    //       }
    //     } else {
    //       observer.next(true);
    //       observer.complete()
    //     }
    //   } else {
    //     this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    //     observer.next(false);
    //     observer.complete()
    //   }
    // });
    // });

    return true;
  }
}
