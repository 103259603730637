import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class LocationService {
    constructor(private http: HttpClient) {
    }


    show(id) {
        return this.http.get<any>(`${environment.apiUrl}/client/show/${id}`);
    }

    store(location) {
        return this.http.post<any>(`${environment.apiUrl}/project/storeLocation`, location);
    }

    update(client) {
        return this.http.put<any>(`${environment.apiUrl}/client/${client.id}` , client);
    }

    delete(id: number) {
        return this.http.delete<any>(`${environment.apiUrl}/client/${id}`);
    }

    getLocations(name = '') {
        return this.http.get<any>(`${environment.apiUrl}/project/location?search=${name}`);
    }

    deleteLocation(param) {
        return this.http.post<any>(`${environment.apiUrl}/project/delete-location`, param);
    }

    updateLocation(id, location) {
        return this.http.put<any>(`${environment.apiUrl}/location/update/${id}`, location);
    }
}
