import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class RegistrationGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(environment.agencyName === 'Hotelis') {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
  
}
