import { Component, OnInit,Inject } from '@angular/core';


@Component({
    moduleId:module.id,
    selector: 'create-example',
    templateUrl: `./notFound404.component.html`,
})
export class NotFound404Component  implements OnInit {

    constructor() {
             
    }


    ngOnInit() {

      
           
    }
}
