import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {AppConfig} from './app.config';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class HttpHelper {

  private apiUrl: string = AppConfig.apiUrlString;

  constructor(private http: HttpClient, private router: Router, private injector: Injector) {
    setTimeout(() => this.router = injector.get(Router));
  }

  get(url): Observable<any> {
    const headers = this.setHeaders();
    return this.http.get(this.apiUrl + url, headers);
  }

  post(url, param): Observable<any> {
    const bodyString = JSON.stringify(param);
    const headers = this.setHeaders();
    return this.http.post(this.apiUrl + url, bodyString, headers);
  }

  postIban(url, param): Observable<any> {
    const bodyString = JSON.stringify(param);
    const headers = this.setHeaders();
    return this.http.post(AppConfig.ibanUrlString + url, bodyString, headers);
  }

  put(url, param): Observable<any> {
    const bodyString = JSON.stringify(param);
    const headers = this.setHeaders();
    return this.http.put(this.apiUrl + url, bodyString, headers);
  }

  delete(url): Observable<any> {
    const headers = this.setHeaders();
    return this.http.delete(this.apiUrl + url, headers);
  }

  setHeaders() {
    if (localStorage.getItem('currentUser') != null) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.api_token) {
        return {headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + currentUser.api_token
        })};
      } else {
        return {headers: null};
      }
    }
  }
}
