import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {AgencyService, ProjectPlanningService} from 'app/_services';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppConfig} from '../../common/app.config';
import {TranslateService} from '@ngx-translate/core';
import { ClothingTranslateModel } from './models/models/clothing-translate.model';

@Component({
  moduleId: module.id,
  selector: 'app-clothing-requirements',
  templateUrl: `./clothing-requirements.component.html`,
  styleUrls: ['./clothin-requirements-style.scss'],
  providers: [ConfirmationService, ProjectPlanningService, AgencyService]
})

export class ClothingRequirementsComponent implements OnInit {

  showModal = false;

  isSubmitted: boolean;
  @Output() saved = new EventEmitter<any>();
  @Input() isArchived = 0;

  roleId = null;
  projectId = null;
  roleName = null;
  crid = null;
  subject = null;
  isNotFilled = false;
  description = null;
  clothingImageUrl = null;
  templates = [];
  agencyId = JSON.parse(localStorage.getItem('currentUser')).agency_id;
  public showTemplateModal = false;
  templateId = null;
  imagesForTemplate = [];
  fieldTranslations = new ClothingTranslateModel();

  public languages = [ 
    { value: 'en', label: 'English' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' }
  ];

  public clothingImageConf = {
    url: AppConfig.storeClothingRequirementImageUrl,
    maxFiles: 2,
    params: {role_id: 0},
    autoDiscover: false,
    acceptedFiles: 'image/jpeg,image/png,image/gif,image/jpg',
    headers: {},
    dictDefaultMessage: this.translate.instant("clothingDropzoneTitle")
  }

  public images = [];

  public currentLanguage = localStorage.getItem('Language');

  constructor(
    private projectPlanningService: ProjectPlanningService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private translate: TranslateService,
    private agencyService: AgencyService
  ) {
    this.isSubmitted = false;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const apiToken = user.api_token;
    this.clothingImageConf.headers = {'Authorization': 'Bearer ' + apiToken};
  }

  ngOnInit() {
    this.projectPlanningService.loadTemplates('clothing').subscribe(response => {
      this.templates = response.templates;
    });
  }

  selectedTab() {
    switch(this.currentLanguage) {
      case 'en': return 0;
      case 'de': return 1;
      case 'fr': return 2;
      case 'it': return 3;
    }
  }

  getClothingRequirement(roleId) {
    this.fieldTranslations = new ClothingTranslateModel();
    this.projectPlanningService.getClothingRequirement(roleId).subscribe(response => {
      if (response.clothing_requirement) {
        this.crid = response.clothing_requirement.id;
        this.subject = response.clothing_requirement.subject;
        this.description = response.clothing_requirement.description;
        if(response.clothing_requirement.field_translations) {
          this.fieldTranslations = JSON.parse(response.clothing_requirement.field_translations);
        } else {
          this.fieldTranslations.subject.de = response.clothing_requirement.subject;
          this.fieldTranslations.description.de = response.clothing_requirement.description;
        }
      } 
      this.images = response.images.map(img => { 
        return {id: img.id, path: img.path};
      });
    })
  }

  onSubmit() {
    if(this.fieldTranslations.subject[this.currentLanguage]){
      this.subject= this.fieldTranslations.subject[this.currentLanguage];
      this.description = this.fieldTranslations.description[this.currentLanguage];
    } else if (this.fieldTranslations.subject.en) {
      this.subject = this.fieldTranslations.subject.en;
      this.description = this.fieldTranslations.description.en;
    } else if (this.fieldTranslations.subject.de) {
      this.subject= this.fieldTranslations.subject.de;
      this.description = this.fieldTranslations.description.de;
    } else if (this.fieldTranslations.subject.fr) {
      this.subject = this.fieldTranslations.subject.fr;
      this.description = this.fieldTranslations.description.fr;
    } else {
      this.subject = this.fieldTranslations.subject.it;
      this.description = this.fieldTranslations.description.it;
    }

    if (!this.subject || !this.description) {
        this.translate.get('Subject and description are required!').subscribe(res => {
            this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
        this.isNotFilled = true;
      return;
    } else{
      this.isNotFilled = false;
    }

    this.projectPlanningService.saveClothingRequirement(
      {id: this.crid, subject: this.subject, description: this.description, role_id: this.roleId, field_translations: JSON.stringify(this.fieldTranslations)})
      .subscribe(response => {
        this.saved.emit();
        this.close();
      });
  }

  onUploadSuccess(event) {
    if (event[1].status === 'error') {
      this.translate.get(event[1]).subscribe(res => {
        this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
      });
    } else {
      this.images = event[1].images;
      this.translate.get('Saved successfully!').subscribe(res => {
        this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
      });
    }
    event[0]._removeLink.click();
  }

  onUploadError(event) {
      this.translate.get(event[1]).subscribe(res => {
          this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
      });
    event[0]._removeLink.click();
  }

  removeImage(id) {
      this.translate.get('Are you sure that you want to delete this image?').subscribe(res => {
          this.confirmationService.confirm({
              header: this.translate.instant("Confirmation"),
              message: res,
              accept: () => {
                  this.projectPlanningService.deleteClothingRequirementImage(id).subscribe(response => {
                      this.translate.get('Deleted successfully').subscribe(resp => {
                          this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: resp});
                      });
                      this.images = response.images;
                  });
              }
          });
      });
  }

  show(role, projectId) {
    this.templateId = role.id;
    this.showTemplateModal = false;
    this.clothingImageUrl = AppConfig.getAgencyFileParh(this.agencyId);
    console.log("The img url ", this.clothingImageUrl);

    this.showModal = true;
    this.clothingImageConf.params = {role_id: role.id};
    this.roleId = role.id;
    this.roleName = role.name;
    this.crid = null;
    this.subject = null;
    this.description = null;
    this.getClothingRequirement(role.id);
    this.projectId = projectId;
  }

  close() {
    this.showModal = false;
  }

  loadTemplate(template) {
    this.projectPlanningService.createClothingRequirementFromTemplate({template_id: template.id, role_id: this.roleId})
      .subscribe(response => {
        console.log(response);
        if (response.clothing_requirement) {
          this.crid = response.clothing_requirement.id;
          this.fieldTranslations = new ClothingTranslateModel();
          if (response.clothing_requirement.field_translations) {
            this.fieldTranslations = JSON.parse(response.clothing_requirement.field_translations);
          } else {
            this.subject = response.clothing_requirement.subject;
            this.description = response.clothing_requirement.description;
          }
        }
        this.images = response.images.map(img => {
          return img;
        });
        this.saved.emit(); 
      });
  }

  deleteClothingRequirement() {
    this.translate.get('Are you sure that you want to delete this?').subscribe(resp => {
      this.confirmationService.confirm({
          header: this.translate.instant("Confirmation"),
          message: resp,
          accept: () => {
            this.projectPlanningService.deleteClothingRequirement(this.crid).subscribe(response => {
              this.close();
              this.saved.emit(); 
            });
          }
        })
      });
  }

  saveTemplate() {
    if(this.fieldTranslations.subject[this.currentLanguage]){
      this.subject= this.fieldTranslations.subject[this.currentLanguage];
      this.description = this.fieldTranslations.description[this.currentLanguage];
    } else if (this.fieldTranslations.subject.en) {
      this.subject = this.fieldTranslations.subject.en;
      this.description = this.fieldTranslations.description.en;
    } else if (this.fieldTranslations.subject.de) {
      this.subject= this.fieldTranslations.subject.de;
      this.description = this.fieldTranslations.description.de;
    } else if (this.fieldTranslations.subject.fr) {
      this.subject = this.fieldTranslations.subject.fr;
      this.description = this.fieldTranslations.description.fr;
    } else {
      this.subject = this.fieldTranslations.subject.it;
      this.description = this.fieldTranslations.description.it;
    }

    this.translate.get('Are you sure that you want to save \'this.subject\' as a template?', { value: this.subject }).subscribe(resp => {
        this.confirmationService.confirm({
            header: this.translate.instant("Confirmation"),
            message: resp,
            accept: () => {
                if (this.subject && this.description) {
                    this.projectPlanningService.saveTemplate(
                        {
                            name: this.subject,
                            description: this.description,
                            type: 'clothing',
                            project_id: this.projectId,
                            role_id: this.roleId,
                            field_translations: JSON.stringify(this.fieldTranslations)
                        })
                        .subscribe(response => {
                            switch(response.status) {
                              case 'success':
                                  this.templates = response.templates;
                                break;
                              case 'exists':
                                this.translate.get('Template with the name {value} already exists. Do you want to save the list?', { value: this.subject }).subscribe(respo => {
                                  this.confirmationService.confirm({
                                    header: this.translate.instant("Confirmation"),
                                    message: respo,
                                    accept: () => {
                                      this.agencyService.updateTemplate(response.template_id, {
                                        name: this.subject,
                                        description: this.description,
                                        type: 'clothing',
                                        project_id: this.projectId,
                                        role_id: this.roleId,
                                        field_translations: JSON.stringify(this.fieldTranslations)
                                    }).subscribe( updatedTemplate => {
                                        this.templates = updatedTemplate.templates;
                                      });
                                    }
                                  });
                                });
                              break;
                              case 'error': 
                                this.translate.get(response.message).subscribe(res => {
                                  this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
                                });
                              break;
                            }
                        });
                } else {
                    this.translate.get('Please fill subject and description before you save this as a template!').subscribe(res => {
                        this.messageService.add({
                            severity: 'error',
                            summary: this.translate.instant('Error!'),
                            detail: res
                        });
                    });
                }
            }
        });
    });
  }
}
