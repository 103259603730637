import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet>
  <p-toast position="top-right"></p-toast>`
})
export class AppComponent implements OnInit {

    constructor(translate: TranslateService ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('de');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        const language = localStorage.getItem('Language');
        if (language) translate.use(language);
    }

    ngOnInit() {}
}
