import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { AuthenticationService } from '../_services';
import { MessageService} from 'primeng/api';

@Injectable(
)
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private messageService: MessageService, 
        private translate: TranslateService,
        public router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(tap(
                succ => {},
                erro => {
                    this.translate.get(erro.error.error.message).subscribe(res => {
                        this.messageService.add({
                            severity: 'error',
                            summary: this.translate.instant('Error!'),
                            detail: res
                        });
                    });
                }),
                catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }
            if(err.status === 403 && err.error.error.message === 'Project does not exist!') {
                this.router.navigate(['']);
            }
            const error = err.error && err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}
