import {Component, OnInit} from '@angular/core';
import {UserModel} from '../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserIdentity} from '../../helpers/common/user.identity';
import {MessageService} from 'primeng/api';
import {AuthenticationService} from '../../_services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'app-staff-register',
  templateUrl: `../views/staff-register.component.html`,
  styleUrls: ['../styles/auth-style.scss'],
  providers: [ MessageService ]
})
export class StaffRegisterComponent implements OnInit {
  public user: UserModel;
  hideform = false;
  token = null;
  role = "";

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
    this.user = new UserModel();
    this.token = this.route.snapshot.params.token;
    console.log(this.token);
  }

  ngOnInit() {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/project';
    if (UserIdentity.isAuthenticated()) {
      this.router.navigate([returnUrl]);
    }
    this.authService.getEmailFromToken(this.token).subscribe(response => {
      if (response.email) {
        this.user.email = response.email;
        this.role = response.role;
        if (response.language) {
          this.translate.use(response.language);
          localStorage.setItem('Language', response.language);
        }
      } else {
          this.translate.get('This link is expired').subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
      }

    });
  }

  signOut(): void {
    // this.authService.signOut();
  }

  onSubmit(profileForm) {
    if (!profileForm.valid) {
      return;
    }
    this.user.email = this.user.email.toLowerCase();
    this.register(this.user);
    return;
  }

  register(user) {
    this.authService.staffRegister({...user, token: this.token}).subscribe(response => {
        if (response.status === 'success') {
          this.hideform = true;

          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
        }
      },
      err => {
          this.translate.get('Sorry, you cannot register. Maybe you have not been invited?!').subscribe(res => {
              this.messageService.add({
                  severity: 'error',
                  summary: this.translate.instant('Error!'),
                  detail: res
              });
          });
      });
  }
}
