import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WageModel} from 'app/_models/wage.model';
import {CriteriaComponent} from '../criteria/criteria.component';
import {SalaryClassComponent} from '../salary-class/salary-class.component';
import {ProjectPlanningService} from 'app/_services';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import { WageTranslateModel } from 'app/_models/wage-translate.model';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  moduleId: module.id,
  selector: 'app-wages',
  templateUrl: `./wages.component.html`,
  styleUrls: ['./style.component.css'],
  providers: [MessageService, ConfirmationService]
})

export class WagesComponent implements OnInit {

  openEditCriteriaModal = false;
  openEditSalaryClassModal = false;
  public currentLanguage = localStorage.getItem('Language');

  
  @ViewChild('criteriaComponent') criteriaComponent: CriteriaComponent;
  @ViewChild('salaryClassComponent') salaryClassComponent: SalaryClassComponent;
  @ViewChild('confirmPopup') confirmPopup;
  @Output() saved = new EventEmitter<any>();
  @Input() isArchived = 0;
  showModal = false;
  role = null;
  showTemplateModal = false;
  showSurchargeTemplateModal = false;


  percentageSurchargeOptions = [];
  foundPercentageSurchargeOptions = [];
  fixedSurchargeOptions = [];
  foundFixedSurchargeOptions = [];

  showSupplementTemplateModal = false;
  wages: Array<WageModel>;
  surcharges: Array<WageModel>;
  onlyWages: Array<WageModel>;
  criterias = [];
  salaryClasses = [];
  templates = [];
  surchargeTemplates = [];
  supplementTemplates = [];
  editTitleModal = false;
  currentWageTranslate = new WageTranslateModel();
  currentWageIndex;
  public languages = [
    { value: "en", label: "English" },
    { value: "de", label: "Deutsch" },
    { value: "fr", label: "Français" },
    { value: "it", label: "Italiano" },
  ];

  isSubmitted: boolean;

  addSurchargeModal = false;
  selectedSurchargeType = 'percentage';

  constructor(
    private projectPlanningService: ProjectPlanningService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService) {
    this.wages = [];
    this.onlyWages = [];
  }

  ngOnInit(): void {
    this.projectPlanningService.getCriterias('wage').subscribe(response => {
      this.criterias = response.map((criteria) => {
        const translatedName = JSON.parse(criteria.field_translations).name[this.currentLanguage]
        return {id: criteria.id, name: criteria.name};
      })
    });

    this.projectPlanningService.getSalaryClasses().subscribe(response => {
      this.salaryClasses = response.map((salaryClass) => {
        const translatedName = JSON.parse(salaryClass.field_translations).name[this.currentLanguage]
        return {id: salaryClass.id, name: salaryClass.name};
      })
    });

    

    this.projectPlanningService.loadTemplates('wage').subscribe(response => {
      this.templates = [];

      for(let i = 0; i < response.templates.length; i++) {
        if(JSON.parse(response.templates[i].body).is_suplement) {} else {
          this.templates.push(response.templates[i])
        }

      }
    });
    this.projectPlanningService.loadTemplates('surcharge').subscribe(response => {
      this.surchargeTemplates = response.templates;
    });
    this.projectPlanningService.loadTemplates('supplement').subscribe(response => {
      this.supplementTemplates = response.templates;
    });


  }

  show(role) {
    this.role = role;
    this.showTemplateModal = false;
    this.projectPlanningService.getWages(role.id).subscribe(response => {

      this.wages = [];
      this.wages = response.map((wage) => {
        let fieldTranslations;


        if(wage.field_translations) {
          fieldTranslations = JSON.parse(wage.field_translations);
          if(typeof fieldTranslations === 'string') 
          fieldTranslations = JSON.parse(fieldTranslations);
        } else {
          fieldTranslations = new WageTranslateModel();
        }
        wage.description = fieldTranslations?.description[this.currentLanguage] || fieldTranslations?.description.de || fieldTranslations?.description.en || fieldTranslations?.description.fr || fieldTranslations?.description.it || wage.description;

        if(!wage.is_suplement && !wage.is_surcharge) {
          this.onlyWages.push(new WageModel(wage.id, wage.role_id, wage.description, wage.amount, wage.max_amount, wage.currency, wage.frequency, wage.criteria_id, wage.is_suplement, wage.percentage, wage.is_surcharge, fieldTranslations, wage.is_fixed_surcharge, wage.salary_class_id));
        }
        return new WageModel(wage.id, wage.role_id, wage.description, wage.amount, wage.max_amount, wage.currency, wage.frequency, wage.criteria_id, wage.is_suplement, wage.percentage, wage.is_surcharge, fieldTranslations, wage.is_fixed_surcharge, wage.salary_class_id);
      });
    }, err => {
      this.showModal = false;
    });
    this.showModal = true;

  }

  close() {
    this.showModal = false;
    this.role = null;
    this.wages = [];
    this.onlyWages = [];
  }

  confirmWageDelete(wageId, index) {
      this.translate.get('Are you sure you want to remove this wage').subscribe(res => {
          this.confirmationService.confirm({
              header: this.translate.instant("Confirmation"),
              message: res,
              accept: () => {
                this.remove(wageId, index);
              }
          });
      });
  }

  remove(wageId, index) {
    if (!wageId) {
      this.wages.splice(index, 1);
        this.translate.get('Wage deleted successfully!').subscribe(res => {
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
        });

        this.onlyWages = [];
        this.wages.map((wage) => {
          if(!wage.is_suplement && !wage.is_surcharge) {
            this.onlyWages.push(new WageModel(wage.id, wage.role_id, wage.description, wage.amount, wage.max_amount, wage.currency, wage.frequency, wage.criteria_id, wage.is_suplement, wage.percentage, wage.is_surcharge, null, wage.is_fixed_surcharge, wage.salary_class_id));
          }
        });

      return;
    }
    this.projectPlanningService.deleteWage(wageId).subscribe(response => {
        this.translate.get('Wage deleted successfully!').subscribe(res => {
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
        });
      this.wages.splice(index, 1);

      this.onlyWages = [];
      this.wages.map((wage) => {
        if(!wage.is_suplement && !wage.is_surcharge) {
          this.onlyWages.push(new WageModel(wage.id, wage.role_id, wage.description, wage.amount, wage.max_amount, wage.currency, wage.frequency, wage.criteria_id, wage.is_suplement, wage.percentage, wage.is_surcharge, null, wage.is_fixed_surcharge, wage.salary_class_id));
        }
      });

    });

  }

  addCriteria(wage, filterValue, index) {
    this.criteriaComponent.show('wage', filterValue, index);
  }

  editCriteria(criteriaId) {
    this.criteriaComponent.editCriteria(criteriaId, false);
  }

  addSalaryClass(filterValue, key, index) {
    this.salaryClassComponent.show(filterValue, key, index);
  }

  editSalaryClass(salaryClassId) {
    this.salaryClassComponent.editSalaryClass(salaryClassId, false);
  }


  addWage(description = null, amount = null, max_amount = null, currency = 'CHF', frequency = null, criteria_id = null, is_suplement = null, percentage = null, is_surcharge = null, field_translations = null, is_fixed_surcharge = false, salary_class_id = null) {
    let trs = new WageTranslateModel();
    trs.description[this.currentLanguage] = description;

    this.wages.push(new WageModel(null, this.role.id, description, amount, max_amount, currency, frequency, criteria_id, is_suplement, percentage, is_surcharge, field_translations ? JSON.parse(field_translations) : trs, is_fixed_surcharge, salary_class_id));
    if(!is_suplement && !is_surcharge) {
      this.onlyWages.push(new WageModel(null, this.role.id, description, amount, max_amount, currency, frequency, criteria_id, is_suplement, percentage, is_surcharge, field_translations ? JSON.parse(field_translations) : trs, is_fixed_surcharge, salary_class_id))
    }
  }

  addSalarySupplement() {
    this.addWage(null, null, null, 'CHF', null, null, true, null, null, null, false, null);
  }

  addSurcharge() {
    this.addWage(null, null, null, 'CHF', null, null, null, null, true, null, this.selectedSurchargeType == 'percentage' ? false : true, null);
    this.addSurchargeModal = false;
  }

  criteriaSaved(event) {
    this.projectPlanningService.getCriterias('wage').subscribe(response => {
      this.criterias = response.map( criteria => {
        const translatedName = JSON.parse(criteria.field_translations).name[this.currentLanguage]
        return {id: criteria.id, name: criteria.name};
      });
      if (event.index !== undefined && event.index !== null) { 
        this.wages[event.index].criteria_id = event.criteria_id;
      }
    });
  }

  salaryClassSaved(event) {
    this.projectPlanningService.getSalaryClasses().subscribe(response => {
      this.salaryClasses = response.map( salary_class => {
        const translatedName = JSON.parse(salary_class.field_translations).name[this.currentLanguage]
        return {id: salary_class.id, name: salary_class.name};
      });
      if (event.index !== undefined && event.index !== null) { 
        this.wages[event.index].salary_class_id = event.salary_class_id;
      }
    });
  }

  onSubmit() {
    if (this.isWagesArrayValid()) {
      this.projectPlanningService.saveWages({role_id: this.role.id, wages: this.wages})
        .subscribe(response => {
            this.translate.get('Wages saved successfully!').subscribe(res => {
                this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
            });
          this.saved.emit();
          this.close();
        });
    }
  }

  isWagesArrayValid() {
    for (let i = 0; i < this.wages.length; i++) {
      if(this.wages[i].is_surcharge) {
        if (!this.wages[i].is_fixed_surcharge && (!this.wages[i].description || !this.wages[i].percentage)) {
            this.translate.get('All fields are required!').subscribe(res => {
                this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
            });
            this.wages[i].isNotFilled = true;
          return false;
        } else if (this.wages[i].is_fixed_surcharge && (!this.wages[i].description || !this.wages[i].amount || !this.wages[i].currency || !this.wages[i].frequency)){
          this.translate.get('All fields are required!').subscribe(res => {
            this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
            });
            this.wages[i].isNotFilled = true;
          return false;
        } else {
          this.wages[i].isNotFilled = false;
        }
      }  else {
        if (!this.wages[i].description || !this.wages[i].frequency || !this.wages[i].currency || !this.wages[i].amount) {
            this.translate.get('All fields are required!').subscribe(res => {
                this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
            });
            this.wages[i].isNotFilled = true;
          return false;
        } else {
          this.wages[i].isNotFilled = false;
        }
      }
    }
    return true;
  }

  loadTemplate(template) {
    const wage: WageModel = JSON.parse(template.body);
    
    if(wage.field_translations) {
      wage.field_translations = JSON.parse(wage.field_translations);
      if(!wage.field_translations.description['en'] && !wage.field_translations.description['de'] && !wage.field_translations.description['fr'] && wage.field_translations.description['it']) {
        wage.field_translations[this.currentLanguage] = wage.description !== "" && wage.description !== null ? wage.description : "Untitled";
      }
      wage.field_translations = JSON.stringify(wage.field_translations);
    }
    this.addWage(template.name, wage.amount, wage.max_amount, wage.currency, wage.frequency, wage.criteria_id, wage.is_suplement, wage.percentage , wage.is_surcharge, wage.field_translations, wage.is_fixed_surcharge, wage.salary_class_id);
  }

  saveTemplate(wage: WageModel) {

    const templateType = wage.is_surcharge ? 'surcharge' : wage.is_suplement ? 'supplement' : 'wage';
      this.translate.get('Are you sure that you want to save \'this.subject\' as a template?', {value: wage.description }).subscribe(res => {
          this.confirmationService.confirm({
              header: this.translate.instant("Confirmation"),
              message: res,
              accept: () => {
                  if ((wage.is_surcharge && wage.percentage && wage.description) || (wage.description && wage.amount && wage.currency && wage.frequency)) {
                      
                      let translations = JSON.stringify(wage.field_translations);
                      this.projectPlanningService.saveTemplate({
                          name: wage.description, type: templateType,
                          body: JSON.stringify({
                              description: wage.description, amount: wage.amount, currency: wage.currency,
                              frequency: wage.frequency, criteria_id: wage.criteria_id, percentage: wage.percentage, is_suplement: wage.is_suplement, is_surcharge: wage.is_surcharge, 
                              field_translations: translations, is_fixed_surcharge: wage.is_fixed_surcharge, salary_class_id: wage.salary_class_id
                          })
                      }).subscribe(response => {
                          if (response.status === 'error') {
                              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: response.message});
                          } else if (response.status === 'success') {
                              this.templates = response.templates;
                          }
                      });
                  } else {
                      this.translate.get('Please fill the row before you save it as template').subscribe(resp => {
                          this.messageService.add({
                              severity: 'error',
                              summary: this.translate.instant('Error!'),
                              detail: resp
                          });
                      });
                  }
              }
          });
      });
  }

  saveCriterias() {
  
  }

  saveSalaryClasses() {
  
  }

  selectedTab() {
    if (this.currentLanguage === "en") {
      return 0;
    } else if (this.currentLanguage === "de") {
      return 1;
    } else if (this.currentLanguage === "fr") {
      return 2;
    } else if (this.currentLanguage === "it") {
      return 3;
    }
  }

  openWageTitle(index, wage) {
    this.editTitleModal = true;
    this.currentWageTranslate = wage.field_translations;
    this.currentWageIndex = index
  }

  storeWageTranslates() {
    if(!this.currentWageTranslate.description['en'] && !this.currentWageTranslate.description['de'] && !this.currentWageTranslate.description['fr'] && !this.currentWageTranslate.description['it']) {
      this.messageService.add({
        severity: 'error', 
        summary: this.translate.instant('Error!'), 
        detail: this.translate.instant('Please enter title on at least one language.')});
      return;
    }
    this.wages[this.currentWageIndex].description = this.currentWageTranslate.description[this.currentLanguage] || this.currentWageTranslate.description.de || this.currentWageTranslate.description.en || this.currentWageTranslate.description.fr || this.currentWageTranslate.description.it
    this.wages[this.currentWageIndex].field_translations = this.currentWageTranslate;
    this.editTitleModal = false;
  }


  openSurchargesTemplatesModal() {
    this.showSurchargeTemplateModal = true;

    this.percentageSurchargeOptions = [];
    this.fixedSurchargeOptions = [];

    this.surchargeTemplates.forEach(template => {
      if (template.is_fixed_surcharge) {
        this.fixedSurchargeOptions.push(template);
      } else {
        this.percentageSurchargeOptions.push(template);
      }
    });
    
    this.foundPercentageSurchargeOptions = this.percentageSurchargeOptions;
    this.foundFixedSurchargeOptions = this.fixedSurchargeOptions;

    this.showSurchargeTemplateModal = true;
  }

  searchPercentageSurcharge(value) {
    this.foundPercentageSurchargeOptions = this.percentageSurchargeOptions.filter(function(element) {
        return element.name.toLowerCase().includes(value.toLowerCase());
    });
  }

  searchFixedSurcharge(value) {
    this.foundFixedSurchargeOptions = this.fixedSurchargeOptions.filter(function(element) {
        return element.name.toLowerCase().includes(value.toLowerCase());
    });
  }

}