import {Component, OnInit} from '@angular/core';
import {UserModel} from '../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../_services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'app-password-reset',
  templateUrl: `../views/password-reset.component.html`,
  styleUrls: ['../styles/auth-style.scss'],
  providers: [
    MessageService
  ]
})

export class PasswordResetComponent implements OnInit {

  public displayPasswordReset: true;
  public passwordTypeField = true;

  public user: UserModel;
  public days: Array<number>;
  public months: Array<number>;
  public years: Array<number>;
  hideform = false;

  token = null;
  passwordResetObject = {
    password: '',
    confirmPassword: ''
  };


  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private messageService: MessageService,
    private translate: TranslateService
  ) {
    this.token = this.route.snapshot.params.token;
  }

  ngOnInit() {
  }

  onSubmit() {
    const passwordPattern = this.passwordResetObject.password;
    if (!passwordPattern.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      this.translate.get('must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters').
          subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
        return;
    }
    this.authService.resetPasswordRequest({token: this.token, password: this.passwordResetObject.password, confirmPassword: this.passwordResetObject.confirmPassword}).subscribe(
      response => {
        if (response.status === 'success') {
            this.translate.get(response.message).subscribe(res => {
              console.log(response)
                this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
            });
          this.router.navigate(['/login']);
        } else {
          this.translate.get(response.message).subscribe(res => {

              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
        }
      }
    );
  }
}
