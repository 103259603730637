import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ExpensesModel} from 'app/_models/expenses.model';
import {SalaryClassComponent} from '../salary-class/salary-class.component';
import {ProjectPlanningService} from 'app/_services';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import { ExpenseTranslateModel } from 'app/_models/expense-translate-model';

@Component({
  moduleId: module.id,
  selector: 'app-expenses',
  templateUrl: `./expenses.component.html`,
  styleUrls: ['./expenses-style.scss'],
  providers: [ConfirmationService]
})

export class ExpensesComponent implements OnInit {

  @ViewChild('criteriaComponent') criteriaComponent;
  @ViewChild('salaryClassComponent') salaryClassComponent: SalaryClassComponent;
  @Output() saved = new EventEmitter<any>();
  @Input() isArchived = 0;

  showModal = false;
  role = null;
  public showTemplateModal = false;
  expenses = [];
  criterias = [];
  salaryClasses = [];
  templates = [];

  isSubmitted: boolean;

  editTitleModal = false;
  currentExpenseTranslate = new ExpenseTranslateModel();
  currentExpenseIndex;

  public languages = [ 
    { value: 'en', label: 'English' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' },
    { value: 'it', label: 'Italiano' }
  ];


  public currentLanguage = localStorage.getItem('Language');

  constructor(
    private projectPlanningService: ProjectPlanningService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService) {
    this.expenses = [];
  }

  ngOnInit(): void {
    this.projectPlanningService.getCriterias('expense').subscribe(response => {
      this.criterias = response.map((criteria) => {
        return {id: criteria.id, name: criteria.name};
      })
    });

    this.projectPlanningService.getSalaryClasses().subscribe(response => {
      this.salaryClasses = response.map((salaryClass) => {
        return {id: salaryClass.id, name: salaryClass.name};
      })
    });

    this.projectPlanningService.loadTemplates('expense').subscribe(response => {
      this.templates = response.templates;
    });
  }

  show(role) {
    this.role = role;
    this.showTemplateModal = false;
    this.projectPlanningService.getExpenses(role.id).subscribe(response => {
      this.expenses = [];
      this.expenses = response.map((expense) => {
        let fieldTranslations;
        if(expense.field_translations) {
          fieldTranslations = JSON.parse(expense.field_translations);
          if(typeof fieldTranslations === 'string') 
            fieldTranslations = JSON.parse(fieldTranslations);
        } else {
          fieldTranslations = new ExpenseTranslateModel();
        }
        
        expense.description = fieldTranslations?.description[this.currentLanguage] || fieldTranslations?.description.de || fieldTranslations?.description.en || fieldTranslations?.description.fr || fieldTranslations?.description.it || expense.description;
        return new ExpensesModel(expense.id, expense.role_id, expense.description, expense.amount, expense.max_amount,
          expense.type, expense.currency, expense.frequency, expense.payment_type, expense.criteria_id, fieldTranslations, expense.salary_class_id);
      });
    }, err => {
      this.showModal = false;
    });
    this.showModal = true;
  }

  close() {
    this.showModal = false;
    this.role = null;
    this.expenses = [];
  }

  remove(expenseId, index) {
    if (!expenseId) {
      this.expenses.splice(index, 1);
        this.translate.get('Expense deleted successfully!').subscribe(res => {
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
        });
      return;
    }
    this.projectPlanningService.deleteExpense(expenseId).subscribe(response => {
        this.translate.get('Expense deleted successfully!').subscribe(res => {
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
        });
      this.expenses.splice(index, 1);
    });
  }

  addCriteria(expense, name, index) {
    this.criteriaComponent.show('expense', name, index);
  }

  addSalaryClass(filterValue, key,  index) {
    this.salaryClassComponent.show(filterValue, key, index);
  }

  addExpense(description = null, amount = null,max_amount = null, type = null, currency = 'CHF', frequency = null, payment_type = null, criteria_id = null, field_translations = null, salary_class_id = null) {
    if (this.isExpensesArrayValid()) {
      let trs = new ExpenseTranslateModel();
      trs.description[this.currentLanguage] = description;
      this.expenses.push(
        new ExpensesModel(null, this.role.id, description, amount,max_amount, type, currency, frequency, payment_type, criteria_id, field_translations ? JSON.parse(field_translations) : trs, salary_class_id)
      );
    }
  }

  editCriteria(criteriaId) {
    this.criteriaComponent.editCriteria(criteriaId, false);
  }

  editSalaryClass(salaryClassId) {
    this.salaryClassComponent.editSalaryClass(salaryClassId, false);
  }

  criteriaSaved(event) {
    this.projectPlanningService.getCriterias('expense').subscribe(response => {
      this.criterias = response.map((criteria) => {
        return {id: criteria.id, name: criteria.name};
      });
      if (event.index !== undefined && event.index !== null) {
        this.expenses[event.index].criteria_id = event.criteria_id;
      }
    });
  }

  salaryClassSaved(event) {
    this.projectPlanningService.getSalaryClasses().subscribe(response => {
      this.salaryClasses = response.map( salary_class => {
        return {id: salary_class.id, name: salary_class.name};
      });
      if (event.index !== undefined && event.index !== null) {
        this.expenses[event.index].salary_class_id = event.salary_class_id;
      }
    });
  }

  onSubmit() {
    if (this.isExpensesArrayValid()) {
      console.log("Saving this expense: ", this.expenses);
      this.projectPlanningService.saveExpenses({role_id: this.role.id, expenses: this.expenses})
        .subscribe(response => {
            this.translate.get('Expense saved successfully!').subscribe(res => {
                this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
            });
          this.saved.emit();
          this.close();
        });
    }
  }

  isExpensesArrayValid() {
    for (let i = 0; i < this.expenses.length; i++) {
      if(this.expenses[i].frequency == 'km') {
        this.expenses[i].payment_type = 'effective';
      }
      if (!this.expenses[i].description || !this.expenses[i].payment_type || !this.expenses[i].frequency || !this.expenses[i].currency
        || !this.expenses[i].type || (this.expenses[i].type !== 'other' && !this.expenses[i].amount)) {
          this.translate.get('All fields except criteria are required!').subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
          this.expenses[i].isNotFilled = true;
        return false;
      } else {
        this.expenses[i].isNotFilled = false;
      }
    }
    return true;
  }

  confirmExpenseDelete(expenseId, index) {
      this.translate.get('Are you sure you want to remove this expense?!').subscribe(res => {
          this.confirmationService.confirm({
              header: this.translate.instant("Confirmation"),
              message: res,
              accept: () => {
                  this.remove(expenseId, index);
              }
          });
      });
  }

  deleteResponse(event) {
    if (!event.answer) return;
    this.remove(event.item.id, event.item.index);
  }

  loadTemplate(template) {
    const expense: ExpensesModel = JSON.parse(template.body);
    if(expense.field_translations) {
      expense.field_translations = JSON.parse(expense.field_translations);
      if(!expense.field_translations.description['en'] && !expense.field_translations.description['de'] && !expense.field_translations.description['fr'] && expense.field_translations.description['it']) {
        expense.field_translations[this.currentLanguage] = expense.description !== "" && expense.description !== null ? expense.description : "Untitled";
      }
      expense.field_translations = JSON.stringify(expense.field_translations);
    }
    this.addExpense(template.name, expense.amount, expense.max_amount, expense.type, expense.currency,
      expense.frequency, expense.payment_type, expense.criteria_id, expense.field_translations, expense.salary_class_id
    );
  }

  saveTemplate(expense: ExpensesModel) {
      this.translate.get('Are you sure that you want to save \'this.subject\' as a template?', {value: expense.description}).subscribe(res => {
          this.confirmationService.confirm({
              header: this.translate.instant("Confirmation"),
              message: res,
              accept: () => {
                  if (expense.description && expense.amount && expense.type && expense.currency && expense.frequency) {
                    let translations = JSON.stringify(expense.field_translations);

                      this.projectPlanningService.saveTemplate({
                          name: expense.description, type: 'expense',
                          body: JSON.stringify({
                              description: expense.description, amount: expense.amount, max_amount: expense.max_amount, type: expense.type, currency: expense.currency,
                              frequency: expense.frequency, payment_type: expense.payment_type, criteria_id: expense.criteria_id, 
                              field_translations: translations, salary_class_id: expense.salary_class_id
                          })
                      }).subscribe(response => {
                          if (response.status === 'error') {
                              this.translate.get(response.message).subscribe(resp => {
                                  this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: resp});
                              });
                          } else if (response.status === 'success') {
                              this.templates = response.templates;
                          }
                      });
                  } else {
                      this.translate.get('Please fill the row before you save it as template').subscribe(resp => {
                          this.messageService.add({
                              severity: 'error',
                              summary: this.translate.instant('Error!'),
                              detail: resp
                          });
                      });
                  }
              }
          });
      });
  }

  selectedTab() {
    if(this.currentLanguage === 'en') {
      return 0;
    } else if (this.currentLanguage === 'de'){
      return 1;
    } else if (this.currentLanguage === 'fr') {
      return 2;
    } else if (this.currentLanguage === 'it') {
      return 3;
    }
  }

  openExpenseTitle(index, expense) {
    this.editTitleModal = true;
    this.currentExpenseTranslate = expense.field_translations;
    this.currentExpenseIndex = index
  }

  storeExpenseTranslates() {
    this.expenses[this.currentExpenseIndex].description = this.currentExpenseTranslate.description[this.currentLanguage] || this.currentExpenseTranslate.description.de || this.currentExpenseTranslate.description.en || this.currentExpenseTranslate.description.fr || this.currentExpenseTranslate.description.it
    this.expenses[this.currentExpenseIndex].field_translations = this.currentExpenseTranslate;
    this.editTitleModal = false;
  }
}
