import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class PermissionService {
  constructor(private http: HttpClient) {
  }

  sidebarPermissions() {
    return this.http.get<any>(`${environment.apiUrl}/sidebar-permissions`);
  }

  canAccessRoute(route) {
    return this.http.get<any>(`${environment.apiUrl}/can-access?route=` + route);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }
}
