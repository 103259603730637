export class SidebarPermissionsModel {
  projects = false;
  new_project = false;
  employees = false;
  clients = false;
  locations = false;
  agency_dashboard = false;
  agency_profile = false;
  agency_client = false;
  orders = false;
  view_staff = false;
  settings = false;
  work_validation_view = false;
}
