import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NotFound404Component} from './notFound404.component';
import {SomethingWrongComponent} from './somethingWrong.component';
import {HttpClientModule} from '@angular/common/http';


const routes = [{path: 'notFound404', component: NotFound404Component},
  {path: 'somethingWrong', component: SomethingWrongComponent}
];

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, RouterModule.forRoot(routes)],
  declarations: [SomethingWrongComponent, NotFound404Component],
  bootstrap: [SomethingWrongComponent],
  exports: [SomethingWrongComponent, NotFound404Component]
})
export class ErrorsModule {
}
