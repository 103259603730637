import { Component, OnInit,Inject } from '@angular/core';


@Component({
    moduleId:module.id,
    selector: 'create-example',
    templateUrl: `./somethingWrong.component.html`,
})
export class SomethingWrongComponent  implements OnInit {

    constructor() {
             
    }


    ngOnInit() {

      
           
    }
}
