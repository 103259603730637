import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class StaffAllocationService {
  constructor(private http: HttpClient) {}

  orderName: string = '';
  
  loadStaffAllocation(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/staff-allocation/${id}`);
  }

  reloadEmployeePanel(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/staff-allocation-panel/${id}`);
  }

  checkMinimalInterval(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/check-minimal-interval`, param);
  }

  getUserAssignments(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/get-user-assignments`, param);
  }

  alreadyAssignedEmployee(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/is-employee-already-assigned`, param);
  }
  
  alreadyAssignedEmployeePrepMode(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/is-employee-already-assigned-prep-mode`, param);
  }

  assignEmployee(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/assign-employee`, param);
  }

  checkExistingTimestampHistories(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/check-existing-timestamp-histories`, param);
  }
  unassignEmployee(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/unassign-employee`, param);
  }

  checkAllocation(param) {
    return this.http.put<any>(`${environment.apiUrl}/project/check-allocation`, param);
  }

  answerCancelRequest(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/answer-cancel-request`, param);
  }

  doClock(param, type) {
    return this.http.post<any>(`${environment.apiUrl}/hire/${type}`, param);
  }

  getDailyActiveAllocations(projectId, locationId) {
    return this.http.get<any>(`${environment.apiUrl}/project/daily-active-allocations?project_id=${projectId}&location_id=${locationId}`);
  }

  removeUnassignedShift(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/remove-unassigned-shift/${id}`)
  }

  openShifts(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/open-shifts`, param);
  }
  
  openAllShifts(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/open-all-shifts`, param);
  }

  closeShifts(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/close-shifts`, param);
  }

  closeAllShifts(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/close-all-shifts`, param);
  }

  rejectApplicant(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/reject-applicant`, param);
  }

  roleEmployees(roleId) {
    return this.http.get<any>(`${environment.apiUrl}/project/role-employees/${roleId}`);
  }

  generateSirandaPDF(param) {
    return this.http.post<any>(`${environment.apiUrl}/generate-siranda-pdf`, param);
  }

  generateInfoPDF(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/generate-info-pdf`, param);
  }

  storeInfoPDFTemplate(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/info-pdf-template`, param);
  }

  getEmployeeConflicts(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/get-employee-conflicts`, param);
  }

  sendNotificationToEmployees(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/send-notification-to-employees`, param);
  }

  saveShiftTimeChange(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/save-shift-time-change`, param);
  }

  loadStaffAllocationWithHistory(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/applications-with-history/${id}`);
  }
}
