import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class SettingsService {
    constructor(private http: HttpClient) {
    }

    invite(param) {
        return this.http.post<any>(`${environment.apiUrl}/join-invitation`, param);
    }
}
