import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdentity } from '../../helpers/common/user.identity';

@Component({
    moduleId: module.id,
    selector: 'app-logout',
    templateUrl: `../views/logout.component.html`
})

export class LogoutComponent implements OnInit {

    constructor(private _router: Router  ) { }

    ngOnInit(): void {
        // reset login status
        UserIdentity.signOut();
        this._router.navigate(['/login']);
    }
}
