import {Component, OnInit} from '@angular/core';
import {UserModel} from '../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserIdentity} from '../../helpers/common/user.identity';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {AgencyService} from '../../_services';
import { environment } from 'environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-ads',
  templateUrl: `../views/ads.component.html`,
  styleUrls: ['../styles/auth-style.scss'],
  providers: [
    MessageService
  ]
})
export class AdsComponent implements OnInit {
  public user: UserModel;
  public days: Array<number>;
  public months: Array<number>;
  public years: Array<number>;
  hideform = false;

  public agencies: Array<any>;
  public seenAgencies: Array<any>;

  public logoUrl = environment.logoUrl;

  constructor(
    private agencyService: AgencyService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private messageService: MessageService,
  ) {
    this.user = new UserModel();
    this.user.salutation = 'Mr';
    const projectId = this.route.snapshot.paramMap.get('id');
    if (projectId !== null) {
      if (+projectId > 0) {
        this.user.agency_id = +projectId;
      }
    }
  }

  ngOnInit() {

    this.agencyService.getAgencies().subscribe(response => {
      this.agencies = response;
    });

    this.days = [];
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }

    this.months = [];
    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }

    this.years = [];
    const currentYear = (new Date()).getFullYear();
    for (let i = currentYear; i >= 1950; i--) {
      this.years.push(i);
    }

    document.body.className = 'login-body';
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/project';
    if (UserIdentity.isAuthenticated()) {
      this.router.navigate([returnUrl]);
    }
  }

  onSubmit(profileForm) {
    if (!profileForm.valid) {
      return;
    }
    this.user.is_social = false;
    this.register(this.user);
    return;
  }

  register(user) {
    // this.authentificationService.register(user).subscribe(response => {
    //   if (response.status === 'error') {
    //     this.messageService.add({severity: 'error', summary: 'Error!', detail: response.message});
    //     this.user.password = '';
    //   } else {
    //     this.messageService.add({severity: 'success', summary: 'Success!', detail: response.message});
    //     this.router.navigate(['/login']);
    //   }
    // });
  }
}
