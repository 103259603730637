export class FilterModel {

  rating = 0;
  preselected_employee_ids = [];
  deselected_employee_ids = [];
  employee_lists = [];
  employee_black_lists  = [];
  public_pool = false;
  name = null;
  gender_m = null;
  gender_f = null;
  age_from = null;
  age_to = null;
  distance_from_main_location = 0;
  distance_from_second_location = 0;
  main_address = null;
  second_address = null;
  eye_color = [];
  has_logged_in: any;
  body_height_from = null;
  body_height_to = null;
  hair_color = [];
  ethnicity = [];
  shoe_size_from = null;
  shoe_size_to = null;
  shirt_size_from = null;
  shirt_size_to = null;
  trouser_width_from = null;
  trouser_width_to = null;
  trouser_length_from = null;
  trouser_length_to = null;
  marital_status = [];
  nationality = [];
  branch_types = [];
  other_branches = false;
  consultant_types = [];
  work_permit = [];
  is_permanent = null;
  permanent_staff = null;
  is_internal = null;
  availability_filter = false;
  strict_availability_filter = false;
  diploma = [];
  job_title = [];
  languages = [];
  language_level = [];

  public_none = false;
  public_half_tax = false;
  public_ga = false;
  public_regional_network = false;
  public_regional_network_name = null;

  l_a1 = false;
  l_a = false;
  l_b = false;
  l_be = false;
  l_c = false;
  l_ce = false;
  l_c1 = false;
  l_c1e = false;
  l_d = false;
  l_d1 = false;
  l_d1e = false;
  l_de = false;
  l_f = false;
  l_g = false;
  l_m = false;

  car_own_car = false;
  car_in_household = false;
  car_no_car = false;
  car_brand = [];
  car_mobility = false;
  car_ubeequo = false;
  car_other = false;

  sport = false;
  tobacco = false;
  logistics = false;
  promoter = false;
  alcoholic_beverages = false;
  automotive = false;
  telecommunication = false;
  financial_products = false;
  pharmaceutical_products = false;
  food = false;
  beauty_body_care = false;
  it = false;
  nonfoods = false;
  pet = false;

  inactive_status = false;
  active_status = true;
  incomplete_status = false;
  applied_status = false;
  edited_status = false;
  expired_status = false;

  weekly_availabilities = [];
  availablePeriods = [];

  latitude = null;
  longitude = null;
  and = null;
  id = null;

  showFilterAvailabilityChbx = false;

  is_deselect_clicked = false;
}

export class LanguageModel {
  name = '';
  level = '';
}

export class AvailabilityModel {
  day = '';
  from = '';
  to = '';
}
