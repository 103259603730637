import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class ProjectPlanningService {
  constructor(private http: HttpClient) {
  }

  show(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/planning/show/${id}`);
  }

  storeRole(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/location-role`, param);
  }

  storePPCRole(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/ppc/location-role`, param);
  }

  cloneRole(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/location-role/clone`, param);
  }

  updateRole(param, id) {
    return this.http.put<any>(`${environment.apiUrl}/project/location-role/${id}`, param);
  }

  deleteRole(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/location-role/${id}`);
  }

  getRoleRequirementData(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/role-requirement-data/${id}`);
  }

  storeShift(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/role-shift`, param);
  }

  updateShift(param, id) {
    return this.http.put<any>(`${environment.apiUrl}/project/role-shift/${id}`, param);
  }

  calculateBreak(from, to) {
      return this.http.get<any>(`${environment.apiUrl}/project/role-shift/break?from=${from}&to=${to}`);
  }

  deleteShift(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/role-shift/${id}`);
  }

  getEmployeeRequirement(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/employee-requirement?project_id=${id}`);
  }

  updateEmplyeeRequirement(param, id) {
    return this.http.put<any>(`${environment.apiUrl}/project/employee-requirement/${id}`, param);
  }

  getCriteria(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/criteria/${id}`);
  }

  getCriterias(type) {
    return this.http.get<any>(`${environment.apiUrl}/project/criteria?type=${type}`);
  }

  storeCriteria(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/criteria`, param);
  }

  updateCriteria(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/project/criteria/${id}`, param);
  }

  deleteCriteria(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/criteria/${id}`);
  }

  getAllCriteria() {
      return this.http.get<any>(`${environment.apiUrl}/project/criteria-all`);
  }

  getSalaryClass(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/salary-class/${id}`);
  }

  getSalaryClasses() {
    return this.http.get<any>(`${environment.apiUrl}/project/salary-class`);
  }

  storeSalaryClass(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/salary-class`, param);
  }

  updateSalaryClass(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/project/salary-class/${id}`, param);
  }

  deleteSalaryClass(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/salary-class/${id}`);
  }

  getAllSalaryClass() {
      return this.http.get<any>(`${environment.apiUrl}/project/salary-class-all`);
  }

  getExpenses(roleId) {
    return this.http.get<any>(`${environment.apiUrl}/project/expense?role_id=${roleId}`);
  }

  saveExpenses(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/expense-array`, param);
  }

  deleteExpense(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/expense/${id}`);
  }

  getWages(roleId) {
    return this.http.get<any>(`${environment.apiUrl}/project/wage?role_id=${roleId}`);
  }

  saveWageSurcharge(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/wage/surcharge`, param);
  }

  updateWageSurcharge(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/project/wage/surcharge/${id}`, param);
  }

  deleteWageSurcharge(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/wage/surcharge/${id}`);
  }

  saveWages(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/wage-array`, param);
  }

  deleteWage(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/wage/${id}`);
  }

  getClothingRequirement(roleId) {
    return this.http.get<any>(`${environment.apiUrl}/project/clothing-requirement/${roleId}`);
  }

  saveClothingRequirement(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/clothing-requirement`, param);
  }

  createClothingRequirementFromTemplate(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/clothing-requirement-from-template`, param);
  }

  createClothingRequirementFromRoleTemplate(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/clothing-requirement-from-role-template`, param);
  }

  deleteClothingRequirement(crId) {
    return this.http.delete<any>(`${environment.apiUrl}/project/clothing-requirement/${crId}`);
  }

  getClothingRequirementImages(roleId) {
    return this.http.get<any>(`${environment.apiUrl}/project/clothing-requirement-images/${roleId}`);
  }

  deleteClothingRequirementImage(criId) {
    return this.http.delete<any>(`${environment.apiUrl}/project/clothing-requirement-images/${criId}`);
  }

  loadTemplates(type) {
    return this.http.get<any>(`${environment.apiUrl}/agency/template?type=${type}`);
  }
  
  loadTemplate(id) {
    return this.http.get<any>(`${environment.apiUrl}/agency/template/${id}`);
  }

  saveTemplate(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/template`, param);
  }

  saveExistingTemplate(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/existing-template`, param);
  }

  checkIfTemplateExists(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/template-exists`, param);
  }

  removeLocationRoleFile(id, param, roleIds = null) {
    const name = '?name=' + encodeURIComponent(param);
    let ids = '';
    if (roleIds) {
      ids = '&role_ids=' + roleIds.join(',');
    }
    return this.http.delete<any>(`${environment.apiUrl}/project/role-file/${id}${name}${ids}`);
  }
  

  getClothRequirement(roleId) {
    return this.http.get<any>(`${environment.apiUrl}/project/clothing-requirement-array/${roleId}`);
  }

  saveClothRequirement(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/clothing-requirement-array`, param);
  }
}
