import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardTestComponent} from './card-test.component';
import {DialogModule} from 'primeng/dialog';


@NgModule({
    imports: [
        CommonModule,
        DialogModule
    ], declarations: [
        CardTestComponent
    ],
    exports: [
        CardTestComponent
    ]
})
export class CardTestModule {
}
