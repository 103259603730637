import {Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-card',
    templateUrl: `./card.component.html`,
    styleUrls: ['./card-style.scss']
})

export class CardTestComponent implements OnInit {
    @Input() options: any;

    constructor(private router: Router,
                private translate: TranslateService) {

    }
    ngOnInit() {}
}
