import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class OrderService {
  constructor(private http: HttpClient) {
  }

  createOrder(order: any) {
    return this.http.post<any>(`${environment.apiUrl}/order/create`, order);
  }

  showOrder(id){
    return this.http.get<any>(`${environment.apiUrl}/order/show/${id}`);
  }

  getOrderShifts(id){
    return this.http.get<any>(`${environment.apiUrl}/orders/order-shifts/${id}`)
  }

  getOrderEmployees(id){
    return this.http.get<any>(`${environment.apiUrl}/orders/get-employees/${id}`)
  }

  getValidations(){
    return this.http.get<any>(`${environment.apiUrl}/orders/validations`)
  }

  updateValidations(validators){
    return this.http.put<any>(`${environment.apiUrl}/orders/validations`, validators)
  }

  inviteValidators(orderId, validators){
    return this.http.post<any>(`${environment.apiUrl}/orders/invite-validators/${orderId}`, validators)
  }

  verifyOrder(token){
    return this.http.get<any>(`${environment.apiUrl}/orders/verify/${token}`)
  }

  getValidationUsers(){
    return this.http.get<any>(`${environment.apiUrl}/orders/validation-users`)
  }

  getOrderBudget(id){
    return this.http.get<any>(`${environment.apiUrl}/orders/check-if-noh/${id}`)
  }

  ordersExport(dateFrom = null, dateTo = null, submissionDate = null, name = '', tab = 'draft-order') {
    let nameString = '';
    let dateFromString = '';
    let dateToString = '';
    let submissionDateString = '';
  
    if (name && name.length > 0) {
      nameString = '&name=' + encodeURIComponent(name);
    }
  
    if (dateFrom) {
      dateFromString = '&from=' + encodeURIComponent(dateFrom);
    } 
  
    if (dateTo) {
      dateToString = '&to=' + encodeURIComponent(dateTo);
    } 
  
    if (submissionDate) {
      submissionDateString = '&submissionDate=' + encodeURIComponent(submissionDate);
    } 
    
    return this.http.get(`${environment.apiUrl}/orders/orders-export?tab=${tab}${nameString}${dateFromString}${dateToString}${submissionDateString}`, {
      responseType: 'blob'  // Set the response type to blob
    });
  }  

  padMonthDay(number) {
    if (number < 10) {
      return '0' + number;
    } else {
      return number;
    }
  }
}