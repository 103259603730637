import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';


declare let mApp: any;
declare let mUtil: any;
@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: "./cms.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class CmsComponent implements OnInit {

    constructor(private _router: Router) {

    }

    ngOnInit() {
    }
}
