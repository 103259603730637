import {environment} from '../../../environments/environment';

export class AppConfig {
  static urlString = environment.url;
  static apiUrlString = environment.apiUrl + '/';
  static ibanUrlString = 'https://services.job.rocks/';

  static storeProfilePicture = AppConfig.apiUrlString + 'user/profile-picture';
  static storeEmployeePictureUrl = AppConfig.apiUrlString + 'employee/upload-image';
  static storeUserFilePath = AppConfig.apiUrlString + 'employee/upload-file';
  static storeBasicAgreementPath = AppConfig.apiUrlString + 'employee/upload-basic-agreement-file';
  static storeEducationCertificatePath = AppConfig.apiUrlString + 'employee/education-file';
  static storeLanguageCertificatePath = AppConfig.apiUrlString + 'employee/language-file';
  static storeClothingRequirementImageUrl = AppConfig.apiUrlString + 'project/clothing-requirement-images';
  static storeClothingTemplateImageUrl = AppConfig.apiUrlString + 'agency/clothing-template';
  static storeTemplateRoleFile = AppConfig.apiUrlString + 'agency/template/role-file';
  static importXml = AppConfig.apiUrlString + 'importDeductions';
  static importPaymentVerificationXml = AppConfig.apiUrlString + 'importPaidXml';
  static storeTermsAndConditionsFile = AppConfig.apiUrlString + 'agency/tos-file';
  static agencyLogoUploadUrl = AppConfig.apiUrlString + 'agency/logo';
  static storeLocationRoleFile = AppConfig.apiUrlString + 'project/role-file';
  static clientLogoUploadUrl = AppConfig.apiUrlString + 'client/logo';
  static importHolidaysFromUrl = AppConfig.apiUrlString + 'agency/public-holiday/import';
  static storeEmailAttachmentFileForContactingEmployees = AppConfig.apiUrlString + 'agency/contact-employees/files';

  static googleClientId = '885437075796-0f6vqc73s6tfu9vuar6i17756ogti1i7.apps.googleusercontent.com';
  static facebookClientId = '231937264026567';


  static getUserPicturePath(userId) {
    // const agencyId = JSON.parse(localStorage.getItem('currentUser')).agency_id;
    // return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/user/${userId}/images/` : null;
    return AppConfig.urlString + `uploads/agency/user/${userId}/images/`;
  }

  static getUserPictureThumbnailPath(userId) {
    // const agencyId = JSON.parse(localStorage.getItem('currentUser')).agency_id;
    // return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/user/${userId}/images/` : null;
    return AppConfig.urlString + `uploads/agency/user/${userId}/images/thumbnails/web/user-profile-avatar/`;
  }

  static getUserFilePath(userId) {
    // const agencyId = JSON.parse(localStorage.getItem('currentUser')).agency_id;
    // return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/user/${userId}/files/` : null;
    return AppConfig.urlString + `uploads/agency/user/${userId}/files/`;
  }

  static getProjectPicturePath(projectId) {
    const agencyId = JSON.parse(localStorage.getItem('currentUser')).agency_id;
    // return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/project/${projectId}/images/` : null;
    return AppConfig.urlString + `uploads/agency/project/${projectId}/images/`;
  }

  static getExportPath(agencyId) {
    return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/payrolling/` : null;
  }

  static getClothingTemplatesPath(agencyId) {
    return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/templates/clothing/` : null;
  }

  static getAgencyFileParh(agencyId) {
      return agencyId ? AppConfig.urlString + `uploads/agency/${agencyId}/files/` : null;
  }

  static getExpensesPicturePath(projectId) {
    return projectId ? AppConfig.urlString + `uploads/agency/project/${projectId}/images/` : null;
  }
}
