export class UserModel {
    salutation: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    password: string;
    birth_day: string;
    birth_month: string;
    birth_year: string;
    is_social: boolean;
    agency_id = null;
    agency_name = null;
    language = null;
    code = null;
}
