import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {
    }

    getUser() {
        return this.http.get<any>(`${environment.apiUrl}/user/profile`);
    }

    getUserById(id) {
        return this.http.get<any>(`${environment.apiUrl}/user/profile/${id}`);
    }

    changePassword(param, userId) {
        return this.http.put<any>(`${environment.apiUrl}/user/password/${userId}`, param);
    }

    updateProfile(param, userId) {
        return this.http.put<any>(`${environment.apiUrl}/user/profile/${userId}`, param);
    }

    updateUserLanguage(param, userId) {
        return this.http.post<any>(`${environment.apiUrl}/user/language/${userId}`, param);
    }

    removeUserPicture(id) {
        return this.http.delete<any>(`${environment.apiUrl}/user/profile-picture/${id}`)
    }

    deleteEmployee(param) {
        return this.http.post<any>(`${environment.apiUrl}/employee/delete`, param);
    }

    updateEmail(param, userId) {
        return this.http.put<any>(`${environment.apiUrl}/user/update-email/${userId}`, param);
    }

    getProjectMessagesBetween(projectId, userId1, userId2) {
        return this.http.get<any>(`${environment.chatApiUrl}/chat/${projectId}/${userId1}/${userId2}`);
    }

    storeMessage(param) {
        return this.http.post<any>(`${environment.chatApiUrl}`, param);
    }
}
