export class ExpensesModel {
  id = null;
  role_id = null;
  description = null;
  amount = null;
  max_amount = null;
  type = null;
  currency = null;
  frequency = null;
  payment_type = null;
  criteria_id = null;
  highlight = false;
  current_template = false;
  isNotFilled = false;
  field_translations = null;
  salary_class_id = null;
  max_amount_visible = false;

  constructor(id, role_id, description, amount, max_amount, type, currency, frequency, payment_type, criteria_id, field_translations, salary_class_id) {
    this.id = id;
    this.role_id = role_id;
    this.description = description;
    this.amount = amount;
    this.max_amount = max_amount;
    this.type = type;
    this.currency = currency;
    this.frequency = frequency;
    this.payment_type = payment_type;
    this.criteria_id = criteria_id;
    this.field_translations = field_translations;
    this.salary_class_id = salary_class_id;
  }
}
