export class UserIdentity {

  static isAuthenticated(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return (currentUser !== null);
  }

  static getUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  static signOut() {
    localStorage.clear();
  }
}
