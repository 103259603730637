import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class FilterService {
  constructor(private http: HttpClient) {
  }

  getFilterTemplates() {
    return this.http.get<any>(`${environment.apiUrl}/project/filter-template`);
  }

  getFilter(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/filter/${id}`);
  }

  filteredEmployeesAmount(filter) {
    return this.http.post<any>(`${environment.apiUrl}/project/filtered-employee-number`, filter);
  }

  filterNumbers(param) {
      return this.http.post<any>(`${environment.apiUrl}/project/filter-numbers`, param);
  }

  deselectAllIds(param) {
      return this.http.post<any>(`${environment.apiUrl}/project/filter-deselect-all`, param);
  }

  saveFilter(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/filter`, param);
  }

  updateFilter(param, id) {
    return this.http.put<any>(`${environment.apiUrl}/project/filter/${id}`, param);
  }

  sendEmailToSelectedEmployees(param) {
      return this.http.post<any>(`${environment.apiUrl}/project/filter/send-emails`, param);
  }
}
