import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {PermissionService} from '../_services';
import {TranslateService} from '@ngx-translate/core';
import { MessageService} from 'primeng/api';

@Injectable()
export class ClientWorkValidationGuard implements CanActivate {

  constructor(private router: Router, private permissionService: PermissionService, private messageService: MessageService, private translate: TranslateService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser) {
      return false;
    }
    return this.permissionService.canAccessRoute('client-work-validation');
  }
}
