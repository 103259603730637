import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../_services';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkValidationDetailsGuard implements CanActivate {

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private messageService: MessageService,
    private translate: TranslateService,
    private location: Location
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser) {
      this.router.navigate(['/login']);
      return false;
    }

    const id = route.paramMap.get('id');
    if (!id) {
      this.delayBackNavigation();
      return false;
    }

    return this.permissionService.canAccessRoute(`work-validation-details/${id}`).pipe(
      map((canAccess) => {
        if (!canAccess) {
          this.delayBackNavigation();
          return false;
        }
        return true;
      }),
      catchError((err) => {
        this.delayBackNavigation();
        return of(false);
      })
    );
  }

  private delayBackNavigation() {
    setTimeout(() => {
      this.location.back();
    }, 2000);
  }
}
