import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {OrderService} from '../../_services';
import { environment } from 'environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-offer-validated',
  templateUrl: `../views/order-validated.component.html`,
  styleUrls: ['../styles/auth-style.scss'],
  providers: [
    MessageService
  ]
})

export class OrderValidatedComponent implements OnInit {
  validating = true;
  token = null;
  success = false;
  failed = false
  alreadyValidated = false;
  message:string = '';
  public mainColor: string;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {
    this.token = this.route.snapshot.params.token;
    this.mainColor = environment.agencyName === "Hotelis" ? "#033768" : "#16a085";
  }

  ngOnInit() {
    this.validateOrder();
  }
  validateOrder() {
    this.validating = false;
    let responseReceived = false;
    const loaderTimeout = setTimeout(() => {
        if (!responseReceived) {
            this.validating = true;
        }
    }, 300);
    new Promise((resolve, reject) => {
        this.orderService.verifyOrder(this.token).subscribe(
            response => resolve(response),
            error => reject(error)
        );
    }).then((response: any) => {
        responseReceived = true;
        clearTimeout(loaderTimeout);
        this.validating = false;

        if (response.status === 'error') {
            this.failed = true;
        } else {
            if (response.message === 'You have already approved this order') {
                this.alreadyValidated = true;
                this.message = response.message;
            } else {
                this.success = true;
            }
        }
    }).catch(() => {
        responseReceived = true;
        clearTimeout(loaderTimeout);
        this.validating = false;
        this.failed = true;
    });
}

}
